<template>
  <div>
    <b-form-checkbox :disabled="weatherDisabled" v-model="showWind" name="check-button-1" switch>
      Show wind
    </b-form-checkbox>
    <b-form-checkbox :disabled="weatherDisabled" v-model="showTemperature" name="check-button-2" switch>
      Show temperature
    </b-form-checkbox>
    <b-badge v-if="weatherDisabled" variant="danger">Select date/time first</b-badge>
    <br>

    <div v-if="($store.state.fickle.weather.weatherwp.length > 0 && $store.state.fickle.weather.weatherwp[0].temperature === 'N/A') ||
                $store.state.fickle.weather.weatherwp.length === 0">
    <b-form-spinbutton style="width: 342px"
        id="sb-date"
        v-model="dateOffset"
        :formatter-fn="dayFormatter"
        min="0"
        max="2"
        wrap
    ></b-form-spinbutton>
    <b-form-spinbutton style="width: 342px"
        id="sb-time"
        v-model="timeOffset"
        :formatter-fn="timeFormatter"
        min="0"
        max="23"
        wrap
    ></b-form-spinbutton></div>

    <img v-if="showTemperature" src="tlegend.png">
    <img v-if="showWind" src="wlegend.png">

    <div v-if="($store.state.fickle.weather.weatherwp.length > 0)">
      <b-badge>Route start time: {{routeTime()}}</b-badge>
    </div>

    <b-badge variant="danger" v-if="$store.state.routes.route.length > 1 && $store.state.routes.info.length === 0">Weather is not available for the route, recalculate it</b-badge>
    <b-badge variant="danger" v-if="$store.state.fickle.weather.weatherwp.length > 0 && !isWeatherAvailable()">Weather is not available for the route</b-badge>

    <div v-if="$store.state.routes.info.length > 1 && $store.state.routes.route.length > 1 && $store.state.routes.route.length > $store.state.fickle.weather.weatherwp.length" align="center" class="text-centercenter text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong>&nbsp;Loading weather...</strong>
    </div>
    <div v-else>

    <b-table v-model="aaa" v-if="$store.state.fickle.weather.weatherwp.length > 0 && $store.state.routes.route.length > 0" selectable sticky-header select-mode="single" ref="wptable1" id="routes-table1" style="max-height: 400px;"
             @row-selected="onRowSelected" @row-clicked="onRowClicked" :items="items" :fields="visible_fields" :tbody-tr-class="rowClass">

      <template #cell(name)="data">
        <span class='smt2s'>{{ data.index }}</span>
      </template>

      <template #cell(temperature)="data">
        <span class='smt2s'>{{ $store.state.fickle.weather.weatherwp[data.index].temperature ?? '' }} °C</span>
      </template>

      <template #cell(wind)="data">
        <span class='smt2s'>{{ $store.state.fickle.weather.weatherwp[data.index].wind ?? '' }} m/s</span>
      </template>

      <template #cell(angle)="data">
        <span class='smt2s'>{{ $store.state.fickle.weather.weatherwp[data.index].windAngle ?? '' }} °</span>
      </template>

      <template #cell(eta)="data">
          <span class='smt2s' v-if="$store.state.routes.info.length > 0 && $store.state.routes.info[data.index]">
            <span v-if="routesStore.routeSettings.startdate === '' || routesStore.routeSettings.starttime === ''">
              {{ new Date(($store.state.routes.info[data.index].fromStartSeconds ?? 0) * 1000).toISOString().substring(11, 16) }}
            </span>
            <span v-else>
              {{ datem($store.state.routes.info[data.index].fromStartSeconds) }}
            </span>
          </span>
      </template>

    </b-table>
    </div>

  </div>
</template>

<script>
import {eventBus} from "@/main";
import store from "@/store";
import {myAxios} from "@/services/myAxios";
import {useRoutesStore} from "@/stores/routes";

import * as dayjs from 'dayjs/dayjs.min'
import {mapStores} from "pinia";

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "WearherSettings",
  data() {
    return {
      prevRowIndex: -1,
      currentRowIndex: -1,
      centerRowIndex: -1,
      focusWPIndex: -1,
      aaa: [],
      weatherInWP: [],
      showWind: false,
      showTemperature: false,
      weatherTime: "15",
      weather: {
        date: new Date().toISOString().split('T')[0],
        time: dayjs().format("HH:mm"),
        nowdate: dayjs().format("DD MMM"),
        nowtime: dayjs().format("HH:mm")
      },
      currentIndex: 0,
      dateOffset: 0,
      timeOffset: 0,
      weatherDisabled: false,
    }
  },
  mounted() {
    this.keepRouteSettings()

    eventBus.$off("init-weather")
    eventBus.$on("init-weather", () => {
      this.showWind = false
      this.showTemperature = false
    })

    eventBus.$off('route-datetime-changed')
    eventBus.$on('route-datetime-changed', () => {

      if (this.$store.state.routes.info.length === 0)
        return

      let weather = []
      let startdate = this.routesStore.routeSettings.startdate
      let starttime = this.routesStore.routeSettings.starttime

      store.commit("fickle/setWeatherUpdating", true)
      store.commit("fickle/setWeatherWP", weather)
      let pts = this.$store.state.routes.route
      pts.forEach((coordinates, i) => {
        let lat = coordinates.lat
        let lon = coordinates.lon

        let seconds = store.state.routes.info[i].fromStartSeconds
        let datetime = dayjs(startdate + " " + starttime).format("YYYY-MM-DD HH:mm")
        let URL = 'https://nogotiles.bgeo.fi:6011/weather_info?lat=' + lat + '&lon=' + lon + '&t=' + datetime.unix()

        myAxios.get(URL).then(resp => {
          weather.push(resp.data)
        }).catch(error => {
          weather.push({temperature: 'N/A', wind: 'N/A', windAngle: 'N/A'})
        })

        store.commit("fickle/setWeatherWP", weather)
      })
      store.commit("fickle/setWeatherUpdating", false)


      store.commit("fickle/setWeatherDateTime", this.dateu(this.$store.state.routes.info[this.currentIndex].fromStartSeconds))
      if (this.showWind || this.showTemperature)
        eventBus.$emit('change-weather')
    })

    eventBus.$off('clear-current-wp-index-weather')
    eventBus.$on('clear-current-wp-index-weather', () => {
      this.currentRowIndex = -1
    })

    eventBus.$off('move-current-weather')
    eventBus.$on('move-current-weather', () => {
      if (this.prevRowIndex !== -1) {
        this.currentRowIndex = this.prevRowIndex
        this.selectRow(this.currentRowIndex)
      }
    })

    eventBus.$off('move-next-weather')
    eventBus.$on('move-next-weather', () => {
      console.log("qqq", this.currentRowIndex)

      if (this.currentRowIndex !== -1) {
        this.currentRowIndex = this.currentRowIndex === this.$store.state.routes.route.length - 1 ? 0 : this.currentRowIndex + 1
        this.prevRowIndex = this.currentRowIndex
        this.selectRow(this.currentRowIndex)
      } else {
        this.selectRow(this.focusWPIndex)
      }
    })

    eventBus.$off('move-prev-weather')
    eventBus.$on('move-prev-weather', () => {
      if (this.currentRowIndex !== -1) {
        this.currentRowIndex = this.currentRowIndex === 0 ? this.$store.state.routes.route.length - 1 : this.currentRowIndex - 1
        this.prevRowIndex = this.currentRowIndex
        this.selectRow(this.currentRowIndex)
        console.log("eee1")
      } else {
        this.selectRow(this.focusWPIndex)
        console.log("eee2", this.focusWPIndex)
      }

    })

    eventBus.$off('select-routetable-row-weather')
    eventBus.$on('select-routetable-row-weather', (id) => {
      console.log("eee", id)
      this.currentRowIndex = id
      this.$refs.wptable1.selectRow(id);
      const tbody = this.$refs.wptable1.$el.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[id]
      row.scrollIntoView({block: 'center'})
    })

    eventBus.$off('set-focus-index-weather')
    eventBus.$on('set-focus-index-weather', (index) => {
      this.focusWPIndex = index
    })

  },
  watch: {
    'showWind'(newVal, oldVal) {
      if (newVal)
        this.showTemperature = false
      this.keepRouteSettings()
      if (this.showWind)
        eventBus.$emit('change-weather')

      if (!this.showWind && !this.showTemperature)
        eventBus.$emit('return-nogo')
    },
    'showTemperature'(newVal, oldVal) {
      if (newVal)
        this.showWind = false

      this.keepRouteSettings()
      if (this.showTemperature)
        eventBus.$emit('change-weather')

      if (!this.showWind && !this.showTemperature)
        eventBus.$emit('return-nogo')
    },
    'dateOffset'() {
      this.weatherDisabled = false
      this.keepRouteSettings()
      if (this.showWind || this.showTemperature)
        eventBus.$emit('change-weather')
    },
    'timeOffset'() {
      this.weatherDisabled = false
      this.keepRouteSettings()
      if (this.showWind || this.showTemperature)
        eventBus.$emit('change-weather')
    }
  },
  computed: {
    ...mapStores(useRoutesStore),
    visible_fields() {
      return [
        {key: 'name', label: '#', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'eta', label: 'ETA', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'temperature', label: 'T', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'wind', label: 'Wind', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'angle', label: 'Direction', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item.isDanger || type !== 'row') return
      if (item.isDanger) return 'table-danger'
    },
    isWeatherAvailable() {
      let val = false
      this.$store.state.fickle.weather.weatherwp.forEach(item => {
        if (item.temperature !== 'N/A') {
          val = true
        }
      })

      return val
    },

    routeTime() {
      let date = this.routesStore.routeSettings.startdate
      let time = this.routesStore.routeSettings.starttime
      // let datetime = moment().day(date).hour(time)
      let datetime = dayjs(date + " " + time).format("DD MMM HH:mm")
      if (datetime === "Invalid date")
        datetime = "N/A"
      return datetime
    },

    dayFormatter(value) {
      let newdate = dayjs().add(value, 'days').format("DD MMM");
      return newdate
    },
    timeFormatter(value) {
      let newtime = dayjs().add(value, 'hour').format("HH:mm");
      return newtime
    },
    getDay() {
      return dayjs().add(this.dateOffset, 'days').format("DD MMM");
    },
    getTime() {
      return dayjs().add(this.timeOffset, 'hour').format("HH:mm");
    },
    getDateTime() {
      let date = dayjs().add(this.dateOffset, 'days')
      let hours = dayjs(date).add(this.timeOffset, 'hour').hour()
      let datetime = dayjs(date.hour(0)).add(hours, 'hour').unix()
      return datetime
    },
    getWeather(lat, lng, time) {
      let URL = 'https://nogotiles.bgeo.fi:6011/weather_info?lat=' + lat + '&lon=' + lng + '&t=' + time

      myAxios.get(URL).then(resp => {
        return resp.data
      })
    },
    selectRow(index) {
      this.$refs.wptable1.unselectRow(index);
      setTimeout( () => {
        this.$refs.wptable1.selectRow(index);
      }, 100)
      this.currentRowIndex = index
      eventBus.$emit('click-wp', index)

    },
    onRowClicked(row, index) {
      this.currentIndex = index
      if (this.$store.state.fickle.weather.weatherwp.length > 0 && this.$store.state.fickle.weather.weatherwp[0].temperature !== 'N/A') {

        store.commit("fickle/setWeatherDateTime", this.dateu(this.$store.state.routes.info[index].fromStartSeconds))
        if (this.showWind || this.showTemperature)
          eventBus.$emit('change-weather')
      }
    },
    onRowSelected(items) {
      eventBus.$emit('wp-selected', items[0])
    },

    keepRouteSettings() {
      console.log("aaa", this.getDateTime())

      store.commit('fickle/setWeatherSettings', {
        showWind: this.showWind, showTemperature: this.showTemperature, datetimeu: this.getDateTime()
      })
    },
    items() {
      return this.$store.state.routes.route
    },
    isDanger(index) {
      let dangers = this.$store.state.routes.dangers.filter(function (e) {
        return e.isDanger === true && e.legIndex === index
      });
      return dangers.length > 0
    },
    datem(seconds) {
      let date = this.routesStore.routeSettings.startdate
      let time = this.routesStore.routeSettings.starttime
      let datetime = dayjs(date + " " + time, "YYYY-MM-DD HH:mm")
      let datetimenew = datetime.add(seconds, "seconds")
      let shortdate = datetimenew.format("DD/MM HH:mm")

      return shortdate
    },
    dateu(seconds) {
      let date = this.routesStore.routeSettings.startdate
      let time = this.routesStore.routeSettings.starttime
      let datetime = dayjs(date + " " + time, "YYYY-MM-DD HH:mm")
      let datetimenew = datetime.add(seconds, "seconds")
      let shortdate = datetimenew.unix()

      return shortdate
    },
  }
}
</script>

<style scoped>

</style>