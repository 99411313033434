<script setup>
</script>

<template>
  <b-container>
    <b-overlay :show="preparingPassagePlan" rounded="sm" spinner-variant="danger">

      <template #overlay>
        <br><br>
        <div class="text-center">
          <b-icon icon="hourglass" variant="warning" font-scale="2" animation="spin"></b-icon>
          <br>
          <b-badge variant="warning">Please wait...</b-badge>
        </div>
      </template>

      <b-button size="sm" variant="warning" class="mt-2" block @click="downloadPDF()">Download PDF
      </b-button>
    </b-overlay>
  </b-container>
</template>

<script>
import {myAxiosRetry} from "@/services/myAxios";
import {createVoyagePlan} from "@/views/routes/components/VoyagePlan";
import {mapStores} from "pinia";
import {useRoutesStore} from "@/stores/routes";
import {useMomentaryStore} from "@/stores/momentary";

export default {
  data() {
    return {
      preparingPassagePlan: false,
    }
  },
  computed: {
    ...mapStores(useRoutesStore, useMomentaryStore),
  },
  methods: {
    createPDF() {
      let points = []

      this.preparingPassagePlan = true

      this.routesStore.route.forEach(item => {
        points.push({lat: item.lat, lon: item.lon})
      })

      return new Promise(resolve => {
        myAxiosRetry.post("api/v1/mss/mapswithlegs", points
        ).then(resp => {
          this.momentaryStore.addChartsByLegs(resp.data)

          myAxiosRetry.post("api/v1/mss/adpenp", points
          ).then(resp => {
            this.momentaryStore.addPubsByLegs(resp.data)

            this.preparingPassagePlan = false
            resolve()
          })
        });
      })
    },
    downloadPDF() {
      createVoyagePlan()
    }
  },
}
</script>

<style scoped>

</style>