import L from "leaflet";
import {file} from "paths.macro";
import {NavLeaf} from "./nav-leaf";
import {eventBus} from "@/main";
import store from "@/store";
import * as utils from "@/utils/utils";
import {getS63CKTInfo} from "@/utils/utils";
import * as colorControl from "./colors-control"
import {PythonArray} from "@/helpers/pythonArray";

import "./extensions/easy-button"
import "./extensions/easy-button.css"

import "leaflet-draw/dist/leaflet.draw-src.css"
import "leaflet-draw/dist/leaflet.draw.css"

import "./extensions/leaflet-search/leaflet-search.min.js"
import "./extensions/leaflet-search/leaflet-search.min.css"

import {CURRENT_PRODUCT, NAVICOM} from "@/utils/build-config";
import './routes/route-styles.css'
import './controls.css'
import router from "@/router";

let orderTextOnChart;
let shipTextOnChart;
let isShipSelected = false;

let goodCells = []
let goodCellsId = []

let cellByRouteId = ""

let upButton = null;

let cellButton = null;

let downButton = null;















import lineIntersect from "@turf/line-intersect";
import polygonToLine from "@turf/polygon-to-line";

import * as turf from '@turf/turf'
import intersect from "@turf/intersect";
// import {lastIndexOf} from "lodash";






class PaneControl {
    constructor(map) {
        this.map = map;
        this.Panes = {};
    }

    get panes_ids_list() {
        return Object.keys(this.Panes);
    }

    do(zind) {
        // console.log(zind)
        if (!this.panes_ids_list.find(x => x == zind)) {
            this.Panes[zind] = this.map.createPane(zind);
            this.Panes[zind].style.zIndex = zind
            // console.log('pane created ' + zind)
        }
        // else console.log('pane already exist' + zind);
    }

    clearPanes() {
        delete this.map._panes.custom;
        delete this.map._paneRenderers.custom;
        this.Panes = {}
    }
}

function ChartsType() {
    this.bigCells = {
        lg: L.layerGroup()
    };
    this.midCells = {
        lg: L.layerGroup()
    };
    this.lilCells = {
        lg: L.layerGroup()
    };
}

export class ChartsLeaf extends NavLeaf {
    customControl;
    customControl1;
    wpArray;
    wpIndex;

    constructor() {
        super();
    }

    legsGroup = null
    wpGroup = null

    wpMarker = L.divIcon({
        className: '',
        iconSize: [20, 20],
        iconAnchor: [10, 10],
        html: "<div style='font-size: 20px'>&#9022;</div>"
    });

    showAnotherRoute() {
        let points = store.state.routes.route

        // let newMarker = new L.marker(points[0], {id: 1, title: 'WP1', icon: this.wpMarker}).addTo(this.wpGroup);

        // let newMarker1 = new L.marker([60.0,30.0], {id: 1, title: 'WP1', icon: this.wpMarker}).addTo(this.map);
        // newMarker1.bindTooltip('WP1', {permanent: true}).openTooltip();
        //
        // let newMarker2 = new L.marker([50.0,30.0], {id: 2, title: 'WP2', icon: this.wpMarker}).addTo(this.map);
        // newMarker2.bindTooltip('WP2', {permanent: true}).openTooltip();
        //
        // let points1 = [newMarker1.getLatLng(), newMarker2.getLatLng()]
        // L.polyline(points1, {color: 'red'}).addTo(this.map);

        points.forEach(wp => {
            let newMarker = new L.marker([wp.lat, wp.lon], {title: 'WP', icon: this.wpMarker}).addTo(this.wpGroup);
            // newMarker.bindTooltip('WP', {permanent: true}).openTooltip();
        })

        L.polyline(points, {color: 'black'}).addTo(this.pmPane);





        // let newMarker1 = new L.marker([60.0,30.0], {id: 2, title: 'WP2', icon: this.wpMarker}).addTo(this.ChartsLayers.overview);
        // L.polyline(points, {color: 'red'}).addTo(this.legsGroup);
    }

    init(el, drawRoute = false, selectCharts = false) {
        // console.log("c--01", new Date().getMinutes(), new Date().getSeconds())
        this.autoscale_bands = true;
        this.chartsSelectable = selectCharts;
        super.init(el);
        this.drawRoute = drawRoute;
        this.catalogueNotHistory = true;
        this.osmLayer = L.tileLayer("https://api.probki.net/map/{z}/{x}/{y}", {
            minZoom: 2,
            maxZoom: 20
        });
        this.map.setMinZoom(2);


        // alert(L.Projection.Mercator.project([58.514164292193364,5.592041015625]))
        //
        //
        // console.log("sssss ", L.Projection.Mercator.project(L.latLng(59.87926917467212,4.932861328125001)))
        // let x = [L.Projection.Mercator.project([58.514164292193364,5.592041015625]),([58.25218341893894,6.19903564453125])]
        // // let bounds = this.$store.state.routes.bounds
        // // let y= lineString([[59.87926917467212, 78.53132125001],[0.39069033049765,4.888916015625001]], {pane: 'line1'});
        // let x1 = [L.Projection.Mercator.project([58.514164292193364,5.592041015625]),L.Projection.Mercator.project([58.514164292193364,5.592041015625])([58.25218341893894,6.19903564453125])]
        // let y= lineString(x1, {pane: 'line1'});
        // // let route = lineString(x)
        // // console.log(route, 'ru')
        // L.geoJSON(y).addTo(this.map);


        // let poly = polygon([[[10, -30], [145, -30], [145, -20], [10, -20], [10, -30]]]);
        // let poly1 = polygon([[[10, -30], [145, -30], [145, -20], [10, -20], [10, -30]]]);
        //
        // let line1 = polygonToLine(poly);
        // var line2 = lineString([[11, -34], [126, -28], [184, -24]], {name: 'line 1'});
        // var line3 = lineString([[11, -10], [126, 12], [184, 24]], {name: 'line 2'});
        // var line4 = lineString([[87, 79], [87, 48]], {name: 'line 3'});

       // let route = lineString([[4.932861328125001, 59.87926917467212],[4.888916015625001, 59.39069033049765]], {pane: 'line1'});
       //  L.geoJSON(route).addTo(this.map);




        // let intersect1 = lineIntersect(line1, line2);
        // let intersect2 = lineIntersect(line1, line3);
        //
        //
        // L.geoJSON(line1).addTo(this.map);
        // // L.geoJSON(line2).addTo(this.map);
        // // L.geoJSON(line3).addTo(this.map);
        // // L.geoJSON(line4).addTo(this.map);
        //
        // console.log("a--1 ", intersect1)
        // // alert(intersect1.features.length > 0)
        // console.log("a--2 ", intersect2)
        // // alert(intersect2.features.length > 0)




        this.legsGroup = L.layerGroup();
        this.map.addLayer(this.legsGroup);
        this.legsGroup.bringToFront

        this.wpGroup = L.layerGroup();
        this.map.addLayer(this.wpGroup);

        // document.getElementsByClassName( 'leaflet-control-attribution' )[0].style.display = 'none';

        this.multiTiles = false

        // let control = L.control.centerCross({show: true, position: "topleft", toggleTitle: 'Toggle crosshair'});
        // this.map.addControl(control);

        this.map.createPane('overview')
        this.map.getPane('overview').style.zIndex = 650;
        this.map.createPane('general')
        this.map.getPane('general').style.zIndex = 660;
        this.map.createPane('coastal')
        this.map.getPane('coastal').style.zIndex = 670;
        this.map.createPane('approach')
        this.map.getPane('approach').style.zIndex = 680;
        this.map.createPane('harbour')
        this.map.getPane('harbour').style.zIndex = 690;
        this.map.createPane('berthing')
        this.map.getPane('berthing').style.zIndex = 700;
        // this.map.createPane('s102')
        // this.map.getPane('s102').style.zIndex = 700;
        this.map.createPane('active')
        this.map.getPane('active').style.zIndex = 710;
        this.activePane = L.layerGroup({}, {pane: 'active'}).addTo(this.map)


        this.scope2digit = {
            overview: 1,
            general: 2,
            coastal: 3,
            approach: 4,
            harbour: 5,
            berthing: 6,


        }

        this.ChartsData = {}
        this.ChartsLayers = {
            overview: L.layerGroup({}, {pane: 'overview'}),
            general: L.layerGroup({}, {pane: 'general'}),
            coastal: L.layerGroup({}, {pane: 'coastal'}),
            approach: L.layerGroup({}, {pane: 'approach'}),
            harbour: L.layerGroup({}, {pane: 'harbour'}),
            berthing: L.layerGroup({}, {pane: 'berthing'}),
            unavailable: L.layerGroup(),
            // s102: L.layerGroup({}, {pane: 's102'}),
        }

        this.overlayMaps = {
            "Overview": this.ChartsLayers.overview,
            "General": this.ChartsLayers.general,
            "Coastal": this.ChartsLayers.coastal,
            "Approach": this.ChartsLayers.approach,
            "Harbour": this.ChartsLayers.harbour,
            "Berthing": this.ChartsLayers.berthing,
            // "S102": this.ChartsLayers.s102,
            "<i>Purchased</i>": this.ChartsLayers.unavailable,
        }

        // this.overlayMapsNavicom =  {
        //         "Navicom" : this.ChartsLayers.general,
        // }

        this.layersControl = L.control.layers({}, this.overlayMaps)
        // var markerLayer = L.layerGroup().addTo(this.map)
        // let marker = L.marker([51.5, -0.09]).addTo(markerLayer);
        // marker.options.title = 'abc'

        // let searchControl = new L.Control.Search({
        //     layer: markerLayer,
        //     moveToLocation: function (latlng, title, map) {
        //         let zoom = map.getBoundsZoom(latlng.layer.getBounds());
        //         map.setView(latlng, zoom); // access the zoom
        //     }
        // })
        //
        // this.map.addControl( searchControl );


        // new L.Control.EasyButton({
        //     states: [{
        //         icon:      'bi bi-search',               // and define its properties
        //         title:     'Search',      // like its title
        //         onClick: (btn) => {       // and its callback
        //             eventBus.$emit("search-maps")
        //         }
        //     }]
        // }).addTo(this.map)

        // L.Map.include({
        //     _initControlPos: function () {
        //         var corners = this._controlCorners = {},
        //             l = 'leaflet-',
        //             container = this._controlContainer =
        //                 L.DomUtil.create('div', l + 'control-container', this._container);
        //
        //         function createCorner(vSide, hSide) {
        //             var className = l + vSide + ' ' + l + hSide;
        //
        //             corners[vSide + hSide] = L.DomUtil.create('div', className, container);
        //         }
        //
        //         createCorner('top', 'left');
        //         createCorner('top', 'right');
        //         createCorner('bottom', 'left');
        //         createCorner('bottom', 'right');
        //
        //         createCorner('top', 'center');
        //         createCorner('middle', 'center');
        //         createCorner('middle', 'left');
        //         createCorner('middle', 'right');
        //         createCorner('bottom', 'center');
        //     }
        // });

        // eventBus.$on('disable-cell', (id) => {
        //     disabledCells.push(id)
        //     console.log(disabledCells)
        // })

        eventBus.$on('enable-cell', (id) => {
        })

        eventBus.$on('set-new-ship-text-on-chart', (shipName) => {
            // shipTextOnChart.updateContent("<span class='chart-text'>&nbsp;Ship: " + shipName + "&nbsp;</span>")
            isShipSelected = true;
        })

        // L.Control.textbox1 = L.Control.extend({
        //     onAdd: function(map) {
        //         let text = L.DomUtil.create('div');
        //         text.id = "chart_text_order";
        //         text.innerHTML = "<span class='chart-text'>&nbsp;New order&nbsp;</span>"
        //         return text;
        //     },
        //     onRemove: function(map) {
        //         // Nothing to do here
        //     },
        //     updateContent: function(text) {
        //         this.getContainer().innerHTML = text;
        //     }
        // });
        // L.control.textbox1 = function(opts) { return new L.Control.textbox1(opts);}
        // orderTextOnChart = L.control.textbox1({ position: 'topright' }).addTo(this.map);

        // L.Control.textbox2 = L.Control.extend({
        //     onAdd: function(map) {
        //         let text = L.DomUtil.create('div');
        //         text.onclick = () => {
        //             console.log("click on select")
        //             eventBus.$emit("select-ship-from-chart")
        //         }
        //         text.id = "chart_text_ship";
        //         text.innerHTML = ""
        //         // text.innerHTML = "<span class='chart-warning-text'>&nbsp;Click to select a ship&nbsp;</span>"
        //         return text;
        //     },
        //     onRemove: function(map) {
        //         // Nothing to do here
        //     },
        //     updateContent: function(text) {
        //         this.getContainer().innerHTML = text;
        //     }
        // });
        // L.control.textbox2 = function(opts) { return new L.Control.textbox2(opts);}
        // shipTextOnChart = L.control.textbox2({ position: 'topright' }).addTo(this.map);

        // eventBus.$on('set-new-order-text-on-chart', (payload) => {
        //     orderTextOnChart.updateContent("<span class='chart-text'>&nbsp;Order №" + payload.order  +" (" + payload.order_date + ")&nbsp;</span>")
        // })

        eventBus.$on('clear-order-text-on-chart', () => {
            // orderTextOnChart.updateContent("<span class='chart-text'>&nbsp;New order&nbsp;</span>")
            // shipTextOnChart.updateContent("<span class='chart-warning-text'>&nbsp;Click to select a ship&nbsp;</span>")
            isShipSelected = false;
        })

        eventBus.$on('show-from-search', (payload) => {
            this.map.setView([payload.lat,payload.lon], 8);
        })

        new L.Control.EasyButton({
            position: 'bottomleft',
            states: [{
                icon:      'bi bi-search',
                title:     'Search',
                onClick: (btn) => {
                    eventBus.$emit('search-on-map')
                }
            }]
        }).addTo(this.map)




































        // eventBus.$on('set-non-primar-layer-control', () => {
        //     alert(2)
        //     this.layersControl.remove();
        //     this.layersControl = L.control.layers({}, this.overlayMaps1)
        // })
        //
        // eventBus.$on('remove-primar-controls', () => {
        //     //TODO NAVICOM
        //     // this.map.removeControl(this.customControl)
        //
        //     // remove customControl
        // })


        this.clearCellslayer();

        this.ChartsLayers.overview.addTo(this.map);
        // this.ChartsLayers.general.addTo(this.map);
        // this.ChartsLayers.approach.addTo(this.map);


        let customControl = L.Control.extend({
            options: {
                position: 'topright'
            },

            onAdd: (map) => {
                let container = L.DomUtil.create('input');
                container.button_state = true;
                container.type = "button";
                container.title = "Toggle bands by map scale ";
                container.value = "Auto-Band ☑";
                container.style.backgroundColor = 'white';
                //container.style.backgroundImage = "url(https://t1.gstatic.com/images?q=tbn:ANd9GcR6FCUMW5bPn8C4PbKak2BJQQsmC-K9-mbYBeFZm1ZM2w2GRy40Ew)";

                container.style.width = '98px';
                container.style.height = '24px';

                container.onmouseover = function () {
                    container.style.backgroundColor = 'lightgray';
                }
                container.onmouseout = function () {
                    container.style.backgroundColor = 'white';
                }

                container.onclick = () => {
                    container.button_state = !container.button_state;
                    this.autoscale_bands = container.button_state
                    container.value = container.button_state ? "Auto-Band ☑" : "Auto-Band ☐"
                }


                return container;
            }
        });


        // downButton = new L.Control.EasyButton({
        //     position: 'bottomright',
        //     states: [{
        //         stateName: 'on',        // name the state
        //         icon:      'bi bi-caret-left-fill',               // and define its properties
        //         title:     'move to prev cell',      // like its title
        //         onClick: (btn) => {       // and its callback
        //             // eventBus.$emit('move-prev' )
        //             console.log(this.wpIndex, 'wpi')
        //             // this.fitBoundsAsync(this.wpArray[this.wpIndex].getBounds())
        //             eventBus.$emit('make-row-active', this.wpArray[this.wpIndex]._layers[this.wpArray[this.wpIndex]._leaflet_id - 1].feature.properties.id)
        //             eventBus.$emit('change-cell-id', this.wpArray[this.wpIndex]._layers[this.wpArray[this.wpIndex]._leaflet_id - 1].feature.properties.id)
        //
        //             console.log(this.wpArray[this.wpIndex], this.wpArray, this.wpIndex, 'wpi')
        //
        //             this.wpIndex-=1
        //         }
        //     },
        //     ]
        // }).addTo(this.map)
        // upButton = new L.Control.EasyButton({
        //     position: 'bottomright',
        //     states: [{
        //         stateName: 'on',        // name the state
        //         icon:      'bi bi-caret-right-fill',               // and define its properties
        //         title:     'move to next cell',      // like its title
        //         onClick: (btn) => {       // and its callback
        //             // eventBus.$emit('move-next')
        //
        //             console.log("mm", goodCells)
        //             console.log("mm", goodCellsId)
        //
        //             // this.fitBoundsAsync(this.wpArray[this.wpIndex].getBounds())
        //             console.log(this.wpArray.length, this.CellsBuRoute.length, 'len')
        //             eventBus.$emit('make-row-active', this.wpArray[this.wpIndex]._layers[this.wpArray[this.wpIndex]._leaflet_id - 1].feature.properties.id)
        //             eventBus.$emit('change-cell-id', this.wpArray[this.wpIndex]._layers[this.wpArray[this.wpIndex]._leaflet_id - 1].feature.properties.id)
        //
        //             console.log(this.wpArray[this.wpIndex], this.wpArray, this.wpIndex, 'wpi')
        //
        //             this.wpIndex+=1
        //         }
        //     },
        //     ]
        // }).addTo(this.map)

        // let customControl2 = L.Control.extend({
        //     options: {
        //         position: 'bottomright'
        //     },
        //
        //     onAdd: (map) => {
        //
        //         eventBus.$on('change-cell-id', (value) => {
        //             container.value = value
        //         })
        //
        //         var container = L.DomUtil.create('input');
        //         container.button_state = true;
        //         container.type = "button";
        //         container.title = "Cells by route";
        //         container.value = cellByRouteId
        //         container.style.backgroundColor = 'white';
        //         container.style.width = '75px';
        //         container.style.height = '24px';
        //
        //         return container;
        //     }
        // });
        //
        // this.map.addControl(this.cellButton = new customControl2())

        let customControl1 = L.Control.extend({
            options: {
                position: 'bottomright'
            },

            onAdd: (map) => {
                let container = L.DomUtil.create('input');
                container.button_state = true;
                container.type = "button";
                container.title = "Add charts by route";
                container.value = "Route";
                container.style.backgroundColor = 'yellow';
                //container.style.backgroundImage = "url(https://t1.gstatic.com/images?q=tbn:ANd9GcR6FCUMW5bPn8C4PbKak2BJQQsmC-K9-mbYBeFZm1ZM2w2GRy40Ew)";

                container.style.width = '75px';
                container.style.height = '24px';

                container.onmouseover = function () {
                    container.style.backgroundColor = 'lightgray';
                }
                container.onmouseout = function () {
                    container.style.backgroundColor = 'yellow';
                }

                container.onclick = () => {
                    router.push('routes')
                }

                return container;
            }
        });

        // this.customControl = this.customControl.bind(this)


        eventBus.$on('add-primar-controls', () => {
            this.map.addControl(this.customControl = new customControl()) //TODO control
            this.map.addControl(this.customControl1 = new customControl1()) //TODO control
        })
        eventBus.$on('remove-primar-controls', () => {
            this.map.removeControl(this.customControl)
            this.map.removeControl(this.customControl1)
        })
        // eventBus.$on('a1', () => {
        //     this.map.removeControl(customControl1)
        // })

        // this.ScaleStateControl = new L.Control.EasyButton({
        //     position: 'topright',
        //     states: [{
        //         stateName: 'on',        // name the state
        //         icon:      'bi bi-file-diff-fill',               // and define its properties
        //         title:     'Turn autoband off',      // like its title
        //         onClick: (btn) => {       // and its callback
        //             // eventBus.$emit('toggle-autoband', true)
        //             this.autoscale_bands = false
        //             btn.state('off');    // change state on click!
        //         }
        //     }, {
        //         stateName: 'off',
        //         icon:      'bi bi-file-diff',
        //         title:     'Turn autoband on',
        //         onClick: (btn) => {
        //             // eventBus.$emit('toggle-autoband', false)
        //             this.autoscale_bands = true
        //             btn.state('on');
        //         }
        //     }]
        // }).addTo(this.map)


        // var drawControl = new L.Control.Draw({
        //     draw: {
        //         polygon: false,
        //         marker: false,
        //         circle: false,
        //         polyline: false,
        //         circlemarker: false
        //
        //     },
        // }).addTo(this.map);
        // new L.Control.EasyButton({
        //     position: 'bottomleft',
        //     states: [{
        //         icon:      'bi bi-square',               // and define its properties
        //         title:     'Select by map bounds',      // like its title
        //         onClick: (btn) => {       // and its callback
        //             eventBus.$emit("bound-select")
        //         }
        //     }]
        // }).addTo(this.map)

        eventBus.$on('bound-select', () => {
            if (this.chartsSelectable) {
                Object.keys(this.ChartsLayers).forEach(lg => {
                    if (this.map.hasLayer(this.ChartsLayers[lg])) {
                        Object.keys(this.ChartsData[lg]).forEach(field => {
                            Object.keys(this.ChartsData[lg][field]).forEach(cell => {
                                if (cell !== 'lg') {
                                    const mapBounds = this.map.getBounds()
                                    let bounds = this.ChartsData[lg][field][cell].cell.getBounds()
                                    if (mapBounds.contains(bounds)) {
                                        this.onCellClicked(cell)
                                    }
                                }
                            })
                        })
                    }
                })
                this.dispatchSelectedCells();
            }
        })
        this.map.on('zoomend', () => {
            // var count = 0
            // this.ChartsLayers.coastal.lg.eachLayer(l => {
            //     count++
            // })
            // console.log(count, 'count')
            console.log(this.map.getZoom(), 'zoom');
            // this.map.setMinZoom(0)
            // if (this.autoscale_bands) {
                this.scaleCharts();
            // }

            let checkBoxes = document.getElementsByClassName('leaflet-control-layers-selector')

            Object.keys(checkBoxes).forEach( digit => {
                checkBoxes[digit].onclick = () => {
                    this.disableAutobands()
                }
            })
        })
        this.map.on('click', () => {
            // this.setCeatalogueNotHistory(true);
            // alert("click")
            // this.scaleCharts();

            console.log("---------------")

            // this.map.contextmenu.hide();
        })
        this.map.on('polylinemeasure:toggle', (e) => {
            if (e.sttus) {
                Object.keys(this.ChartsLayers).forEach(cl => {
                    this.ChartsLayers[cl].removeFrom(this.map);
                });
                eventBus.$emit('draw-control-toggle', true)
            } else if (!e.sttus) {
                this.ChartsLayers.overview.addTo(this.map);
                // this.ChartsLayers.general.addTo(this.map);
                // this.ChartsLayers.approach.addTo(this.map);
                eventBus.$emit('draw-control-toggle', false)
            }
        });

        // this.map.on('polylinemeasure:finish', () => {
        //     //TODO: enable spinner (doesnt work)
        //     eventBus.$emit('go-spinny', true)
        //     this.map.spin(true);
        //     let y = setTimeout(() => {
        //         // console.log(this.pMeasure)
        //         this.computeCharts()
        //             .then(() => {
        //                 eventBus.$emit('go-spinny', false);
        //                 // eventBus.$emit('charts-loaded');
        //                 this.map.spin(false);
        //             })
        //     }, 1000)
        //
        // });
        // this.map.on('polylinemeasure:move', () => {
        //     this.clearSelectedCells();
        //     this.computeCharts();
        // });
        // this.map.on('polylinemeasure:clear', () => {
        //     this.clearSelectedCells();
        //
        // });


        this.paneControl = new PaneControl(this.map)

        eventBus.$on('cell-clicked', (id) => {
                // let mapBounds = this.map.getBounds()
                //
                // if (this.chartsSelectable) {
                //     Object.keys(this.ChartsLayers).forEach(lg => {
                //         if (this.map.hasLayer(this.ChartsLayers[lg])) {
                //             Object.keys(this.ChartsData[lg]).forEach(field => {
                //                 Object.keys(this.ChartsData[lg][field]).forEach(cell => {
                //                     if (cell !== 'lg') {
                //                         let bounds = this.ChartsData[lg][field][cell].cell.getBounds()
                //                         if (mapBounds.contains(bounds)) {
                //                             this.onCellClicked(cell)
                //                         }
                //                     }
                //                 })
                //             })
                //         }
                //     })
                //     this.dispatchSelectedCells();
                // }
            console.log(this.chartsSelectable)
                if (this.chartsSelectable) {

                    this.onCellClicked(id)
                        .then(() => {
                            this.dispatchSelectedCells();
                        })
                }
            }
        )

        // eventBus.$on('aaaa', (text) => {
        //     console.log(text)
        // })

        eventBus.$on('fix-scale', () => {
            // this.layersControl.remove();
            // this.layersControl = L.control.layers({}, this.overlayMaps, {collapsed: false}).addTo(this.map);
            // alert("fix")
            // this.map.fire("viewreset");
            // this.map.fire("resize");
            // this.map.fire("zoomlevelschange");
            // this.scaleCharts()
            // this.map.setZoom(12)
            // this.scaleCharts()
        })

        eventBus.$on('draw-map-spinner', (flag) => {
            this.map.spin(flag);

            // this.map.remove(text2)
            // this.map.remove(customControl1)

            // eventBus.$emit('a1')


            // text2.updateContent("")
            // this.map.removeControl(customControl1)

            // $("#info_text")[0].innerHTML = "aaaa";

        })


        eventBus.$on('s63-order-finished1', () => {
            this.clearSelectedCells();
        })
        eventBus.$on('pane2cell', (payload) => {
            const prev = this.autoscale_bands
            this.autoscale_bands = false
            this.showHighlight(payload["id"], payload["items"], payload["color"]).then( () => {
                this.autoscale_bands = prev

            })
        })
        eventBus.$on('find-cells', e => {
            // alert("find clicked")
            console.log(e)

            // if (!isShipSelected)
            //     eventBus.$emit("select-ship-from-chart")
            // else
                this.findChartsOnRightClick(e)
        })
        eventBus.$on('purchased-cell-clicked', (id) => {
            // alert("cell clicked1")
            // TODO
            // this.setSpecialTiles('https://win1hetz.bgeo.fi:5005/' + id + '/{z}/{x}/{y}');
            console.log('purchased cell clicked', id)
        })
        eventBus.$on("s63-order-finished2", () => {
            this.setMultiTiles();
        })
        eventBus.$on('sumary-open', () => {
            this.map.ChartsLayers.unavailable.addTo(this.map)
        })
        eventBus.$on('show-cell-on-map', (url) => {
            // TODO
            // this.setSpecialTiles(url);
        })
        eventBus.$on('make-cell-active', id => {
            // alert(id)
            const m = this.match(id);
            const model = store.state.current.cart.s63cells.find(x => x.id == id);

            // console.log(id, m, 'match')

            // alert(m)
            let lefId = Object.keys(m.cell._layers)[0]
            const layer = m.cell._layers[lefId]
            console.log(layer, 'layer')
            // layer.setStyle({color: colorControl.ACTIVE_CELL_COLOR})
            const isSelected = m.scope === 'selectedCells'
            console.log(layer, id, isSelected, 'mra')
            let type = m.cell._layers[lefId].feature.properties.unavailable ? 'unavailable' : m.cell._layers[lefId].feature.properties.type;
            m.cell.bringToFront();
            colorControl.changeActiveCell(layer, id, isSelected, m.cell, type, model.disabled)
            console.log(m.cell.getLayer, 'layer')
            // this.disableAutobands()
            // const selected = store.state.current.cart.s63cells
            // // console.log(selected, feature.properties.id)
            // let cellIsAlreadySelected = false
            // if (selected && selected.find(enc => enc.id === feature.properties.productId)){
            //     cellIsAlreadySelected = true;
            // }
            // colorControl.changeActiveCell(layer, id, cellIsAlreadySelected)
        })
        // console.log("c--02", new Date().getMinutes(), new Date().getSeconds())
    }

    showAppState(aindex, lat, lon, azimuth, id, isActive, name, route, sog, email, gpsDate, appStateDate, routeJson = [], posbyship = false) {

        this.scaleCharts();
        console.log('show appstate:: start')
        super.showAppState(aindex, lat, lon, azimuth, id, isActive, name, route, sog, email, gpsDate, appStateDate, routeJson = [], posbyship = false)
        console.log('show appstate:: end')
    }

    // devideRouteBySegments(){
    //     var segmentedRoute = [];
    //     let wpoints = store.state.routes.route
    //     let route = []
    //
    //     wpoints.forEach(wp => {
    //         // console.log("wp -- ", wp)
    //         route.push([wp.lon, wp.lat])
    //     })
    //
    //     // console.log("wp -- ", route)
    //
    //     const routeStringLine = lineString(route);
    // }

    findChartsOnRightClick(e) {

        // if (e === 'hide') {
        //     this.map.contextmenu.hide();
        // } else {
        const latlon = e.e.latlng
        const type = e.props.type;
        const id = e.props.id;

        const title = e.props.name;
        const purchased = e.props.unavailable ? "<br>" + '' : '';
        const paid = e.props.unavailable ? "(bought) " : "";
        const m = this.match(id)
        const scope = m.scope;
        const field = m.field;
        // // console.log(m, id)
        const addChart = function (lg, cell) {
            console.log(lg, cell)
            if(lg !== 'selectedCells') eventBus.$emit('cell-clicked', cell);
            else eventBus.$emit('purchased-cell-clicked', cell);

        }
        // console.log(scope, this.ChartsData[scope])
        // if (scope && this.ChartsData[scope]){
        //     console.log("a1")
        //     const clickedChart = scope == 'selectedCells' ? this.ChartsData.selectedCells[id] : this.ChartsData[scope][field][id];
        //     const clickedChartId = clickedChart.cell
        //     // colorControl.changeActiveCell(clickedChart)
        //
        //     // console.log(cell.getBounds())
        //
        //     const activeBounds = clickedChartId.getBounds();
        //     console.log(this.map.hasLayer(this.ChartsLayers['overview'], 'lg'))
        //     Object.keys(this.ChartsLayers).forEach(lg => {
        //         if (this.map.hasLayer(this.ChartsLayers[lg])) {
        //             console.log(lg, 'lg')
        //             console.log(this.map.hasLayer(this.ChartsLayers[lg]), 'lg')
        //             console.log(this.map.hasLayer(this.ChartsLayers['overview']), 'lg')
        //             Object.keys(this.ChartsData[lg]).forEach(field => {
        //                 Object.keys(this.ChartsData[lg][field]).forEach(cell => {
        //                     if (cell !== 'lg') {
        //                         let bounds = this.ChartsData[lg][field][cell].cell.getBounds()
        //                         if (!scope) console.log(scope, 'a6')
        //
        //                         // console.log(bounds.contains(activeBounds), this.scope2digit[lg] <= this.scope2digit[scope], 'a6')
        //                         // console.log(this.scope2digit[lg], this.scope2digit[scope], 'a6')
        //                         // console.log(cell, 'a6')
        //                         if (!this.scope2digit[scope]) console.log(scope, cell, id, 'a6');
        //                         else console.log(scope, cell, id, 'a6')
        //
        //                         if (bounds.contains(activeBounds) && this.scope2digit[lg] < this.scope2digit[scope]) {
        //                             addChart(lg, cell)
        //                             console.log(cell, lg, 'a5')
        //                         }
        //                     }
        //                 })
        //             })
        //         }
        //     })
        // }

        addChart(scope, id);
        eventBus.$emit('make-row-active', id)
            // this.map.contextmenu.removeAllItems();
            // // ДОБАВЛЕНИЕ
            // // const icon = (!Object.keys(this.ChartsData.selectedCells).find(x => x === id)) ? "bi bi-bag-plus-fill": 'bi bi-bag-dash'
            // const icon = (!Object.keys(this.ChartsData.selectedCells).find(x => x === id)) ? "bi bi-toggle-off" : 'bi bi-toggle-on'
            //
            // this.map.contextmenu.addItem(
            //     {
            //         text: paid + "<span class='smt1'>" + id + "</span>" + " " + "<i>" + type + "</i>" + "<br>" + "<b>" + title + "</b>",
            //         // + purchased ? "<b>Purchased</b>" : "",
            //         iconCls: purchased ? 'bi bi-cash-coin' : this.chartsSelectable ? icon : '',
            //         hideOnSelect: true,
            //         callback: !purchased ?
            //             function () {
            //                 eventBus.$emit('cell-clicked', id);
            //             } :
            //             function () {
            //                 eventBus.$emit('purchased-cell-clicked', id);
            //             },
            //         index: 0
            //     }
            // )
            // this.map.contextmenu.addItem(
            //     {
            //         text: "underlying cells",
            //         disabled: true,
            //         separator: true,
            //         index: 1
            //     }
            // )

                // this.map.contextmenu.showAt(latlon);
        //     })
        //     // УДАЛЕНИЕ
        //     Object.keys(this.ChartsData.selectedCells).forEach(key => {
        //         if (key != 'lg') {
        //             let cell = this.ChartsData.selectedCells[key].cell;
        //             console.log(cell)
        //             let bounds = cell.getBounds();
        //             if (this.isPointInsidePolygon(bounds, latlon) && key !== id) {
        //                 let type = cell._layers[cell._leaflet_id - 1].feature.properties.type;
        //                 this.map.contextmenu.addItem(
        //                     {
        //                         text: "<i class='bi bi-bag-dash'></i> " + type + " " + key,
        //                         // hideOnSelect: false,
        //                         callback: function () {
        //                             eventBus.$emit('cell-clicked', key);
        //                         },
        //                     }
        //                 )
        //             }
        //         }
        //     })
        // }
    }

    setCeatalogueNotHistory(flag) {

        if (this.highLight) {
            this.highLight.eachLayer(layer => {
                layer.setStyle({color: "#656565"})
            })
            this.highLight.clearLayers();
        }
        if (flag === false) {
            Object.keys(this.ChartsLayers).forEach(cl => {
                this.ChartsLayers[cl].removeFrom(this.map);
            });
            this.highLight = new L.layerGroup();
            this.highLight.addTo(this.map);
            this.makeChartsUnselectable();
        } else if (flag === true && !this.catalogueNotHistory) {
            this.highLight.removeFrom(this.map);
            this.ChartsLayers.overview.addTo(this.map);
            // this.ChartsLayers.general.addTo(this.map);
            // this.ChartsLayers.approach.addTo(this.map);
        }
        this.catalogueNotHistory = flag;
    }
    fitBoundsAsync(bounds){

        return new Promise( resolve =>{
            this.map.fitBounds(bounds);
            resolve()
        })
    }
    // setViewAsync(pt){
    //     return new Promise( resolve =>{
    //         this.map.setView(pt);
    //         resolve()
    //     })
    // }
    showHighlight(currentId, items, color) {
        return new Promise( resolve => {
            let id = currentId;
            let match = this.match(id);
            let field = match.field;
            let scope = match.scope;

            if (scope === null)
                return


            console.log("===============")
            console.log("---------------")
            console.log(scope, id, field)
            // console.log(this.ChartsData.selectedCells[id])
            // console.log(this.ChartsData.selectedCells[id].cell)
            console.log("2 ---------------")


            let cell = scope == 'selectedCells' ? this.ChartsData.selectedCells[id].cell : this.ChartsData[scope][field][id].cell;

            console.log("4 ---------------")

            cell.bringToFront();
            let bounds = cell.getBounds();
            console.log("0000 ", bounds)
            let coordinates = bounds.getCenter()
            console.log("0000 ", coordinates)
            let zoom = 8
            if (field == 'midCells') zoom = 5
            if (field == 'bigCells') zoom = 4
            // let pt = cell.getCenter();
            // this.map.setView(coordinates, zoom);

            let bandType = cell._layers[cell._leaflet_id - 1].feature.properties.type
            let cellCenter = cell.getBounds().getCenter()

            let zoomLevel = 9

            // alert(bandType)
            if (bandType === 'overview')
                zoomLevel = 2
            else if (bandType === 'general')
                zoomLevel = 4
            else if (bandType === 'coastal')
                zoomLevel = 5
            else if (bandType === 'approach')
                zoomLevel = 8

            this.map.flyTo(cellCenter, zoomLevel);

            // setTimeout(() => {
            //     alert(this.map.getZoom())            },
            //     4000)



            // if (bandType === 'overview')
            //     this.map.setZoom(4)
            // else if (bandType === 'general')
            //     this.map.setZoom(5)
            // else
            //     this.map.setZoom(8)
            // this.map.setZoom(8)
            console.log("jjjj", cell)
            console.log("jjjj", cell._layers[cell._leaflet_id - 1].feature.properties)
            console.log("jjjj", cell._layers[cell._leaflet_id - 1].feature.properties.id)
            console.log("jjjj", cell._layers[cell._leaflet_id - 1].feature.properties.type)


            // this.fitBoundsAsync(bounds).then( () =>{
            //     setTimeout(() => {
            //         // let zoom = this.map.getZoom()
            //         // this.map.setZoom(zoom-1)
            //         resolve()
            //     }, 500)
            // })

        })

    }


    isPointInsidePolygon(poly, pt) {
        let polyPoints = poly
        let x = pt.lat, y = pt.lng;
        // let x = 60, y = 25;
        let ne = poly._northEast;
        let sw = poly._southWest;

        let inside = false;
        // console.log(x, y, ne, sw)

        inside = x <= ne.lat && x >= sw.lat && y <= ne.lng && y >= sw.lng;

        return inside;
    }

    isRouteIntersectPolygon(route, polygon){

    }
    computeCharts(polyLine) {

        if (!cellButton) {

            let customControl2 = L.Control.extend({
                options: {
                    position: 'bottomright'
                },

                onAdd: (map) => {

                    eventBus.$on('change-cell-id', (value) => {
                        container.value = value
                    })

                    let container = L.DomUtil.create('input');
                    container.button_state = true;
                    container.type = "button";
                    container.title = "Cells by route";
                    container.value = cellByRouteId
                    container.style.backgroundColor = 'white';
                    container.style.width = '75px';
                    container.style.height = '24px';

                    return container;
                }
            });

            this.map.addControl(this.cellButton = new customControl2())

            this.upButton = new L.Control.EasyButton({
                position: 'bottomright',
                states: [{
                    stateName: 'on',        // name the state
                    icon: 'bi bi-caret-down-fill',               // and define its properties
                    title: 'Move to next cell',      // like its title
                    onClick: (btn) => {       // and its callback
                        // eventBus.$emit('move-next')

                        console.log("mm", goodCells)
                        console.log("mm", goodCellsId)

                        // this.fitBoundsAsync(this.wpArray[this.wpIndex].getBounds())
                        console.log(this.wpArray.length, this.CellsBuRoute.length, 'len')
                        eventBus.$emit('make-row-active', this.wpArray[this.wpIndex]._layers[this.wpArray[this.wpIndex]._leaflet_id - 1].feature.properties.id)
                        eventBus.$emit('change-cell-id', this.wpArray[this.wpIndex]._layers[this.wpArray[this.wpIndex]._leaflet_id - 1].feature.properties.id)

                        console.log(this.wpArray[this.wpIndex], this.wpArray, this.wpIndex, 'wpi')

                        this.wpIndex += 1
                    }
                },
                ]
            }).addTo(this.map)

            this.downButton = new L.Control.EasyButton({
                position: 'bottomright',
                states: [{
                    stateName: 'on',        // name the state
                    icon: 'bi bi-caret-up-fill',               // and define its properties
                    title: 'Move to previous cell',      // like its title
                    onClick: (btn) => {       // and its callback
                        // eventBus.$emit('move-prev' )
                        console.log(this.wpIndex, 'wpi')
                        // this.fitBoundsAsync(this.wpArray[this.wpIndex].getBounds())
                        eventBus.$emit('make-row-active', this.wpArray[this.wpIndex]._layers[this.wpArray[this.wpIndex]._leaflet_id - 1].feature.properties.id)
                        eventBus.$emit('change-cell-id', this.wpArray[this.wpIndex]._layers[this.wpArray[this.wpIndex]._leaflet_id - 1].feature.properties.id)

                        console.log(this.wpArray[this.wpIndex], this.wpArray, this.wpIndex, 'wpi')

                        this.wpIndex -= 1
                    }
                },
                ]
            }).addTo(this.map)

        }


        // this.map.addControl(this.customControl1 = new customControl1())

        let wpoints = store.state.routes.route
        let route = []
        this.routeSegments = [];
        let cells = [];
        let products = [];


        for (let i = 0; i < wpoints.length; i += 1) {
            let wp = wpoints[i]
            route.push([wp.lon, wp.lat])
            if (i > 0) {
                let prevWp = wpoints[i - 1]
                this.routeSegments.push(
                    [[prevWp.lon, prevWp.lat], [wp.lon, wp.lat]]
                )
            }
        }
        // console.log(this.routeSegments, 'srp')

        const routeStringLine = turf.lineString(route);
        // console.log(routeStringLine, 'srp')

        const routeStringLineSplit = turf.lineChunk(routeStringLine, 2, {units: 'kilometers'});
        // console.log(routeStringLineSplit, 'srp')

        let points = []
        routeStringLineSplit.features.forEach(point => {
            points.push(point.geometry.coordinates[0])
            points.push(point.geometry.coordinates[1])
        })

        let uniquePoints = Array.from(new Set(points.map(JSON.stringify)), JSON.parse)
        console.log(uniquePoints, 'srp points')

        let cellBands = ["berthing", "harbour", "approach", "coastal", "general"]
        let cellTypes = ["bigCells", "midCells", "lilCells"]

        goodCells = []
        goodCellsId = []
        this.CellsBuRoute = []
        this.wpIndex = 0;

        let pg = null
        let bn = ""

        uniquePoints.forEach(point => {
            let stopFlag = false

            let lat = point[0]
            let lon = point[1]
            let pt = turf.point([lat, lon])

            if ((bn === 'berthing' || bn === 'harbour') && pg) {
                if (turf.booleanPointInPolygon(pt, pg)) {
                    return
                }
            }

            cellBands.every(band => {
                if (stopFlag) return false;
                cellTypes.every(type => {
                    if (stopFlag) return false;

                    Object.keys(this.ChartsData[band][type]).every(cellId => {
                        if (this.ChartsData[band][type][cellId].cell) {
                            let cell = this.ChartsData[band][type][cellId].cell
                            let coordinates = cell._layers[cell._leaflet_id - 1].feature.geometry.coordinates

                            pg = turf.polygon([[
                                coordinates[0][0],
                                coordinates[0][1],
                                coordinates[0][2],
                                coordinates[0][3],
                                coordinates[0][0]
                            ]])
                            stopFlag = turf.booleanPointInPolygon(pt, pg)
                            if (stopFlag) {
                                bn = band
                                if (!goodCellsId.includes(cellId)){
                                    goodCells.push(cell)
                                    goodCellsId.push(cellId)
                                    this.CellsBuRoute.push(cell)
                                    console.log("srp +", cellId)
                                }
                                return false;
                            }
                        }
                        return true
                    })
                    return true
                })
                return true
            })
        })
        this.wpArray = PythonArray.from(this.CellsBuRoute);

        goodCells.forEach(cell => {
            if (!cells.find(x => x == cell._layers[cell._leaflet_id - 1].feature.properties.id) &&
                !products.find(x => x == cell._layers[cell._leaflet_id - 1].feature.properties.productId)

            ) {
                cells.push(cell)
            }
        })

        console.log("aaa", cells)

        cells.forEach(cell => {
            this.onCellClicked(cell._layers[cell._leaflet_id - 1].feature.properties.id)
        })
        this.dispatchSelectedCells();
        console.log(this.upButton, "upbutton")
        setTimeout(() => {this.upButton.button.click()}, 1000)

    }

    computeCharts1(polyLine) {

        let wpoints = store.state.routes.route
        let route = []
        this.routeSegments = [];



        for (let i=0;i<wpoints.length;i+=1){
            let wp = wpoints[i]
            route.push([wp.lon, wp.lat])
            if (i > 0){
                let prevWp = wpoints[i-1]
                this.routeSegments.push(
                    [[prevWp.lon, prevWp.lat], [wp.lon, wp.lat]]
                )
            }
        }
        console.log(this.routeSegments, 'srp')


       const routeStringLine = turf.lineString(route);



        let ltlList
        console.log(file, 'processing route: start')
        return new Promise((resolve, error) => {
            console.log(this.pMeasure._arrPolylines, 'aa')


                let x = this.pMeasure._arrPolylines[0];
                if (!x) {
                    eventBus.$emit('go-spinny', false);
                    // eventBus.$emit('charts-loaded');
                    this.map.spin(false);
                    error();
                }

                ltlList = x.polylinePath._latlngs;
                x.polylinePath.addTo(this.pmPane)

            let cells = [];
            let products = [];
            let coordss = null;
            let latlon = null
            let x1 = []
            let x2 = []
            let x3 = []

            this.CellsBuRoute = []
            var interPts = []
            this.newSegments = []
            this.routeSegments.forEach(seg => {
                var newSeg = [seg[0], seg[1]];
                let match = false;
                Object.keys(this.ChartsData).forEach(key => {
                    if (match) return;
                    if (key != 'selectedCells') {
                        Object.keys(this.ChartsData[key]).forEach(field => {
                            if (match) return;
                            if (field != 'lg') {
                                Object.keys(this.ChartsData[key][field]).forEach(id => {
                                    if (match) return;
                                    if (this.ChartsData[key][field][id].cell) {

                                        let cell = this.ChartsData[key][field][id].cell
                                        if (cell._layers[cell._leaflet_id - 1].feature.properties.type != 'overview'
                                            && cell._layers[cell._leaflet_id - 1].feature.properties.type != 'general') {
                                            // console.log(ce
                                            let bounds = cell._layers[cell._leaflet_id - 1].getBounds();

                                            // console.log(bounds)
                                            let arr = cell._layers[cell._leaflet_id - 1].feature.geometry.coordinates
                                            let lastPoint = arr[0][0]
                                            arr[0].push(lastPoint)
                                            let line1 = turf.lineString(arr[0]);
                                            // let intersection = lin
                                            const segments = this.routeSegments


                                                var segLine = turf.lineString(seg);
                                                var inter = lineIntersect(line1, segLine);

                                                // let ppp = turf.point([56.10773, 16.932678])
                                                let ppp = turf.point(seg[0])

                                                if (inter.features.length > 0) {
                                                    x1.push(inter)
                                                    interPts = []
                                                    // this.CellsBuRoute.push(cell)
                                                    inter.features.forEach(fea => {
                                                        interPts.push({coords:turf.point(fea.geometry.coordinates), cell: cell})
                                                    })
                                                    var iii = interPts.sort((fea1, fea2) => {
                                                        let dist1 = turf.distance(fea1.coords, ppp);
                                                        let dist2 = turf.distance(fea2.coords, ppp);
                                                        if (dist1 < dist2) {
                                                            return -1;
                                                        } else if (dist1 === dist2) {
                                                            return 0;
                                                        } else {
                                                            return 1;
                                                        }

                                                    })
                                                    x2.push(iii)
                                                }
                                                if (interPts.length>0){
                                                    // console.log(inter, 'inter')


                                                    for (let i = 0;i<interPts.length ; i+=1 ){
                                                        let coords = interPts[i].coords.geometry.coordinates;
                                                        console.log(interPts[i], 'eee')
                                                        x3.push(turf.distance(coords, seg[0]))

                                                        // let x = [seg[0],seg[1] ]
                                                        // x.splice(i+1, 0, coords);
                                                        newSeg.splice(i+1, 0, coords);
                                                        if (!cells.find(x => x == cell._layers[cell._leaflet_id - 1].feature.properties.id) &&
                                                                        !products.find(x => x == cell._layers[cell._leaflet_id - 1].feature.properties.productId)

                                                                    ) {
                                                                        this.CellsBuRoute.push(interPts[i])

                                                                        // cells.push(cell._layers[cell._leaflet_id - 1].feature.properties.id)
                                                                        // products.push(cell._layers[cell._leaflet_id - 1].feature.properties.productId)
                                                                    }
                                                    }
                                                }

                                            console.log(this.routeSegments, 'rs')

                                        }
                                    }
                                })
                            }
                        })
                    }
                })
                this.newSegments.push(newSeg);
            })
this.CellsBuRoute.forEach(i => {
    console.log(i, 'iii')
    let cell = i.cell
    cells.push(cell._layers[cell._leaflet_id - 1].feature.properties.id)
    products.push(cell._layers[cell._leaflet_id - 1].feature.properties.productId)
})
            this.wpArray = PythonArray.from(this.CellsBuRoute)
            this.wpIndex = -1
            // console.log("b1++", x1)
            // console.log(x3, 'x3')
            // console.log(this.CellsBuRoute, 'cbr')
            //
            // console.log("ddd")
            // console.log("ddd", interPts)
            //
            // interPts.forEach(item => {
            //     console.log("ddd", item.geometry.coordinates)
            // })
            //
            // x1.forEach(item => {
            //     console.log("b1++1", x1)
            //     // item.features.sort( (fea)=>{
            //     //     return turf.distance(fea.geometry.coordinates, seg[0])
            //     // })
            //
            //     // console.log("b1++2", x2)
            // })
            // x2.forEach(feas=> {
            //     feas.forEach(fea => {
            //         console.log(fea.geometry.coordinates, 'b1++2')
            //
            //     })
            // })



            // })
            // console.log("a--2", new Date().getMinutes(), new Date().getSeconds())
            // console.log(coordss);
            // console.log(latlon);
            // console.log(cells)

            this.segmentedRoute = [];

            this.newSegments.forEach(seg => {
                seg.forEach(pt => {
                    let duplicateCount = 0//seg.filter(coords => (Math.abs(pt[0] - coords[0]) < 0.00001 && Math.abs(pt[0] - coords[0]) < 0.00001));
                    this.segmentedRoute.forEach(coords => {
                        // seg.forEach(coords => {
                            if ((Math.abs(pt[0] - coords[0]) < 0.001 && Math.abs(pt[0] - coords[0]) < 0.001)){
                                duplicateCount += 1
                            }
                        // })
                    })
                    if (duplicateCount < 1)this.segmentedRoute.push(pt)
                    console.log(duplicateCount, 'dupl')
                })
            })
            this.newSegmentedRoute = []
            console.log(this.CellsBuRoute, 'tsr')
            for (let i=0;i<this.segmentedRoute.length;i+=1){
                let wp = this.segmentedRoute[i]
                console.log(wp, 'wp')
                this.newSegmentedRoute.push([wp[1], wp[0]])
                if (i > 0){
                    let prevWp = this.segmentedRoute[i-1]
                    this.newSegmentedRoute.push(
                        [[prevWp[1], prevWp[0]], [wp[1], wp[0]]]
                    )
                }
            }
            // this.newSegmentedRoute.forEach(segment => {
            //     let match = false;
            //     console.log(segment, 'segment')
            //     var latlngs = [
            //         segment,
            //     ];
            //
            //     var polyline = L.polyline(latlngs, {color: 'red'}).addTo(this.map)
            //     console.log(polyline, 'poly')
            //     Object.keys(this.ChartsData).forEach(key => {
            //         if (match) return;
            //         if (key != 'selectedCells') {
            //             Object.keys(this.ChartsData[key]).forEach(field => {
            //                 if (match) return;
            //                 if (field != 'lg') {
            //                     Object.keys(this.ChartsData[key][field]).forEach(id => {
            //                         if (match) return;
            //                         if (this.ChartsData[key][field][id].cell) {
            //
            //                             let cell = this.ChartsData[key][field][id].cell
            //                             // if (cell._layers[cell._leaflet_id - 1].feature.properties.type != 'overview'
            //                             //     && cell._layers[cell._leaflet_id - 1].feature.properties.type != 'general'
            //                             //     && cell._layers[cell._leaflet_id - 1].feature.properties.type == 'coastal') {
            //                             //
            //                             //     let bounds = cell._layers[cell._leaflet_id - 1].getBounds();
            //                             //     let lineBounds = polyline._bounds;
            //                             //     console.log(polyline, 'bounds')
            //                             //
            //                             //     if (bounds.contains(lineBounds)) {
            //                             //         console.log('aaaa-- ', cell)
            //                             //         match = true
            //                             //
            //                             //
            //                             //
            //                             //     }
            //                             //
            //                             // }
            //                         }
            //                     })
            //                 }
            //             })
            //         }
            //     })
            // })

            // console.log("b--1", new Date().getMinutes(), new Date().getSeconds())
            new Set(cells).forEach(id => {
                this.onCellClicked(id)
            })
            // console.log("b--2", new Date().getMinutes(), new Date().getSeconds())
            console.log(file, 'processing route: end')
            this.dispatchSelectedCells();
            resolve();
            this.pMeasure.remove()
            this.makeChartsSelectable()
        })
    }

    clearSelectedCells() {
        console.log(file, 'clear selected cells')
        let linksToProducts = store.state.charts.s63.products.linksToProducts;
        let products = store.state.charts.s63.products.all;
        let toRemoveList = [];
        let productsList = [];
        let selc = this.ChartsData.selectedCells
        Object.keys(selc).forEach(c => {
            if (c != "lg") {
                productsList.push(linksToProducts[c]);
            }
        })
        console.log(productsList)
        new Set(productsList).forEach(x => {
            toRemoveList.push(
                products.find(y => y.id === x).productCellList.productCells[0].id
            )
        })
        console.log(toRemoveList)
        toRemoveList.forEach(x => {
            this.onCellClicked(x);
        })
        console.log(file, 'clear selected cells: end')
        // this.dispatchSelectedCells();
    }

    selectChartsByRoute() {
        this.clearRoute();
        this.add_pMeasure(true);
        // console.log(this.pMeasure)

        let a = document.getElementById('polyline-measure-control')
        if (a)
            a.hidden = false;

    }

    makeChartsSelectable() {
        this.chartsSelectable = true;
        // this.setCeatalogueNotHistory(true)
    }

    makeChartsUnselectable() {
        this.chartsSelectable = false;

    }

    getSelectedCells() {
        let stuf = []
        Object.keys(this.ChartsData.selectedCells).forEach(key => {
            if (key != 'lg') {
                let cell = this.ChartsData.selectedCells[key].cell;
                let lefId = Object.keys(cell._layers)[0];
                console.log(cell._layers[lefId].feature.properties.id, cell._layers[lefId].feature.properties.productId)

                // let otherStuf = store.state.charts.s63.primar_map_info.filter( x => {
                //     return x.id == cell._layers[lefId].feature.properties.productId;
                // })
                stuf.push(cell._layers[lefId].feature.properties.productId);
            }
        })
        console.log(stuf, 'stuf')
        return stuf;
    }

    scaleCharts() {
        console.log('scale:start')
        let zoom = this.map.getZoom();
        console.log(zoom)
        if (this.autoscale_bands) {
            Object.keys(this.ChartsLayers).forEach(name => {

                if (name != 'unavailable' && name != 's102') {
                    console.log(name)
                    const minz = this.getMinZFromUsageBand(name);
                    const maxz = this.getMaxZFromUsageBand(name);

                    if (zoom >= maxz - 7) {
                        console.log(name + " removed")
                        this.ChartsLayers[name].removeFrom(this.map);
                    } else {
                        if (zoom >= minz - 7) {
                            console.log(name + " added")
                            this.ChartsLayers[name].addTo(this.map);
                        } else {
                            console.log(name + " removed")
                            this.ChartsLayers[name].removeFrom(this.map);
                        }
                    }
                }
            })
        }
        // alert(zoom)
        if (zoom < 3) {
            this.refreshLayers('bigCells');
        } else if (zoom < 4) this.refreshLayers('midCells');
        else if (zoom < 9) this.refreshLayers('lilCells');
        console.log('scale:end')
    }

    refreshLayers(cells = null) {
        Object.keys(this.ChartsLayers).forEach(key => {
            this.ChartsLayers[key].clearLayers();
            if (cells) {
                this.ChartsData[key][cells].lg.addTo(this.ChartsLayers[key]);
            }
        })
    }

    refreshData() {
        if (this.ChartsData.selectedCells) this.ChartsData.selectedCells.lg.removeFrom(this.map);
        this.ChartsData = {
            overview: new ChartsType(),
            general: new ChartsType(),
            coastal: new ChartsType(),
            approach: new ChartsType(),
            harbour: new ChartsType(),
            berthing: new ChartsType(),
            unavailable: new ChartsType(),
            // s102: new ChartsType(),
            selectedCells: {lg: L.layerGroup()}
        }

        Object.keys(this.ChartsData).forEach(key => {
            if (key != 'selectedCells') {
                this.ChartsData[key].lilCells.lg.addTo(this.ChartsLayers[key]);
                this.ChartsData[key].midCells.lg.addTo(this.ChartsLayers[key]);
                this.ChartsData[key].bigCells.lg.addTo(this.ChartsLayers[key]);
            }
        })
        this.ChartsData.selectedCells.lg.clearLayers();
        this.ChartsData.selectedCells.lg.addTo(this.map);
    }

    drawCell(data, styleType) {

        // alert("draw cell")
        let zind = data.properties.zind;
        if (zind) {
            zind = String(zind);
            this.paneControl.do(zind);
        }
        let pane = zind ? zind : data.properties.type;


        let cellStyle = {color: "red", fillOpacity: 0.1, weight: 2, opacity: 1};
        switch (styleType) {
            case 1:
                cellStyle = {color: "pink", fillOpacity: 0.05, weight: 1, opacity: 1};
                break;
            case 2:
                cellStyle = {color: colorControl.DEFAULT_CELL_COLOR, fillOpacity: 0.05, weight: 1, opacity: 1};
                break;
            case 3:
                cellStyle = {color: "#dfff9b", fillOpacity: 0, weight: 2, opacity: 1};
                break;
        }
        const toggleCellColor = (id, layer, feature) => {

            // console.log(selected)
            if (id === feature.properties.id){
                console.log(id, feature.properties.id);
                layer.setStyle({color: colorControl.ACTIVE_CELL_COLOR});}
            else {
                layer.setStyle({color: colorControl.SELECTED_CELL_COLOR});
                console.log(feature.properties.id)
            }
            // eventBus.$off('make-cell-active');
        }
        var cell = L.geoJson(data
            , {
                onEachFeature: function (feature, layer) {
                    layer.bindContextMenu();
                    // layer.bindPopup(feature.properties.name, {pane: 'aaa'});
                    const purchased = feature.properties.unavailable ? "<br>" + "<b>Purchased</b>" : ''
                    layer.bindTooltip(feature.properties.id + " (" + feature.properties.type + ")" + "<br>" +feature.properties.name +"<br>" +  purchased, {sticky: true, pane: "aaa"})
                    layer.on('click', (e) => {
                            // alert("layer clicked")
                            // layer.closePopup();
                            // if (!feature.properties.unavailable) {
                            //     eventBus.$emit('cell-clicked', feature.properties.id);
                            // }
                            // else {
                            //     eventBus.$emit('purchased-cell-clicked', feature.properties.id)
                            // }
                            eventBus.$emit('find-cells', {e: e, props: feature.properties})
                        console.log(layer, 'layer')
                        }
                    );
                    // layer.on('hover',  () => {layer.openTooltip();})
                    // layer.on('contextmenu', () => {
                    //     eventBus.$emit('find-cells', 'hide')
                    // })


                },
                style: cellStyle
            }
        );

        let field = this.matchFieldBySize(cell);
        let lefId = Object.keys(cell._layers)[0]
        let type = cell._layers[lefId].feature.properties.unavailable ? 'unavailable' : cell._layers[lefId].feature.properties.type;
        if (field && cell._layers[lefId].feature.properties.id && type) {
            this.ChartsData[type][field][cell._layers[lefId].feature.properties.id] = {};
            this.ChartsData[type][field][cell._layers[lefId].feature.properties.id].cell = cell;
            cell.addTo(this.ChartsData[type][field].lg);
        }
    }

    resetMainLayer() {
        this.mainLayer.remove();
        this.mainLayer = L.tileLayer("https://api.probki.net/map/{z}/{x}/{y}").addTo(this.map);
        if (this.layersControl) this.layersControl.remove()
    }

    resetAll() {
        // this.resetMainLayer();
        // this.clearCellslayer();
        //this.clearAppStates();
    }

    drawAllCells(allCharts) {
        console.log("1122 primar", allCharts)
        console.log("c--1", new Date().getMinutes(), new Date().getSeconds())
        // alert(1)
        console.log(file, 'draw all cells: start')
        let flag

        store.dispatch('current/addLoadingMarker', "my_lead|drawAllCells");

        eventBus.$emit('loading-data', true);
        // this.setDefaultTiles();
        this.clearCellslayer();
        // store.dispatch('addS63Items', [])

        let summary = store.state.charts.s63.summary;

        var x = 0;
        const chData = store.state.charts.map.ChartsFeatures
        this.ChartsFeatures = chData ? chData : []
        if (!chData){
            console.log(allCharts)

            allCharts = allCharts.filter(function (e) {
                return e.productSpecNumber !== 111;
            });

            allCharts.forEach((chart) => {
                if ( chart.usageBand != 's102'){
                    let borders = [[[chart.west, chart.north], [chart.east, chart.north],
                        [chart.east, chart.south], [chart.west, chart.south]]];
                    let geometry = {type: "Polygon", coordinates: borders}

                    let chartFeatures = getS63CKTInfo(chart.id);
                    let price = chartFeatures.price;

                    let notForSale = false;
                    if (summary) {
                        notForSale = summary.find(x => x.id === chart.id) ? true : false;
                    }

                    // if (notForSale) console.log(notForSale)
                    let periods = chartFeatures.period;

                    let chartType = chart.usageBand === 's102' ? 's102' : utils.getBandName(chart.usageBand);


                    if (!notForSale) {
                        const z = ((ne, sw) => {
                            let d = Math.hypot(ne.lat - sw.lat, ne.lng - sw.lng);
                            return 10 / d;
                        })({lat: chart.east, lng: chart.north}, {lat: chart.west, lng: chart.south})

                        let pane = Number(this.map.getPane(chartType).style.zIndex);

                        var zi = Math.round(pane + z);
                        zi = zi > pane + 9 ? pane + 9 : zi;
                        x++

                    }

                    if (!chart.productId) {
                        flag = true
                        console.log(chartType)
                    }

                    var cellJson = {
                        type: "Feature",
                        properties: {
                            name: chart.title,
                            id: chart.id,
                            type: chartType,
                            price: price,
                            unavailable: notForSale,
                            periods: periods,
                            productId: chart.productId,
                            zind: zi
                        },
                        geometry: geometry
                    };

                    let styleType = (store.state.charts.s63.products.multiPart[chart.productId]) ? 1 : 2;
                    if (notForSale) styleType = 3

                    this.drawCell(cellJson, styleType);
                    this.ChartsFeatures.push({cellJson: cellJson,styleType: styleType })
                }


            })
            if (flag) {
                alert('PROBLEMA, ' + file)
                console.log('PROBLEMA, ' + file)
            }
        }
        else {
            this.ChartsFeatures.forEach(feat => {
                this.drawCell(feat.cellJson, feat.styleType)
            })
        }

        this.scaleCharts();

        // let urls = [];
        // summary.forEach( chart => {
        //     const url =  'https://win1hetz.bgeo.fi:5005/'+chart.id+'/{z}/{x}/{y}';
        //     urls.push(url);
        // })
        // this.setMultiTiles(urls)
        this.addChartLayers();
        this.setMultiTiles()
        // console.log(Object.keys(this.ChartsData.s102.lilCells).length)

        eventBus.$emit('loading-data', false);

        store.dispatch('current/removeLoadingMarker', "my_lead|drawAllCells");

        console.log(file, 'draw all cells: end')

        // this.scaleCharts();

        // this.map.setZoom(2);

        let checkBoxes = document.getElementsByClassName('leaflet-control-layers-selector')

        Object.keys(checkBoxes).forEach( digit => {
            checkBoxes[digit].onclick =  () => {
                this.disableAutobands()
            }
        })
        store.state.current.cart.s63cells.forEach(cell => {
            this.onCellClicked(cell.id)
            if (cell.disabled) {
                this.unselectCell(cell.id)
            }
            var flag = false;
            if (!flag) {
                let cellObj = this.match(cell.id).cell;
                this.fitBoundsAsync(cellObj.getBounds()).then(() => {
                    let zoom = this.map.getZoom() - 1;
                    let center = this.map.getCenter()
                    this.map.setView(center, zoom)
                })
                // this.map.setViewAsync(cellObj.getCenter())
                flag = true
            }
        })
        if (store.state.routes.use_for_order){
            // alert(1)
            this.computeCharts(store.state.routes.route)
            // alert(2)
        }
        colorControl.init(this.activePane, this.ChartsLayers, this.ChartsData)
        this.makeChartsSelectable()
        if (store.state.charts.map.ChartsFeatures === undefined)
            store.commit('setChartsFeatures', this.ChartsFeatures)

        // setTimeout(() => {
        //     eventBus.$emit('charts-loaded');
        // }, 5000)

        // alert(2)
        console.log("c--2", new Date().getMinutes(), new Date().getSeconds())
    }

    disableAutobands(){
        this.autoscale_bands = false
        this.customControl.getContainer().button_state = false
        this.customControl.getContainer().value = "Autobands ☐"
    }

    unselectCell(id){
        const cell = this.match(id).cell
        const model = store.state.current.cart.s63cells.find(x => x.id == id);
        // let cell =this.ChartsData.selectedCells[id].cell
        // let lefId = Object.keys(cell._layers)[0]
        // let newScope = cell._layers[lefId].feature.properties.type
        // this.ChartsData[newScope][field][id] = {};
        // this.ChartsData[newScope][field][id].cell = cell;
        // // let color = '#656565';
        // let color = colorControl.DEFAULT_CELL_COLOR;
        //
        // // cellStyle = {color: "#dfff9b", fillOpacity: 0, dashArray: 2, weight: 2, opacity: 1};
        //
        // this.ChartsData[newScope][field][id].cell.setStyle({color: colorControl.DEFAULT_CELL_COLOR, fillOpacity: 0.05, dashArray: 1, weight: 1, opacity: 1});
        // this.ChartsData.selectedCells.lg.removeLayer(cell);
        // cell.addTo(this.ChartsData[newScope][field].lg);
        // delete this.ChartsData.selectedCells[id];
        colorControl.syncSelectedStatus(id, true, cell, model.disabled)
        // cell.!model.disabled();
        // cell.addTo(this.activePane)
    }
    replaceCell(scope, field, id) {
        const model = store.state.current.cart.s63cells.find(x => x.id == id);
        console.log(scope, field, id)

        console.log("a2")
        let cell = scope == 'selectedCells' ? this.ChartsData.selectedCells[id].cell : this.ChartsData[scope][field][id].cell;
        let lefId = Object.keys(cell._layers)[0]
        let newScope = (scope == 'selectedCells') ? cell._layers[lefId].feature.properties.type : 'selectedCells';
        if (!cell._layers[lefId].feature.properties.unavailable) {
            if (newScope == 'selectedCells') {

                console.log("a3")
                this.ChartsData.selectedCells[id] = {};
                this.ChartsData.selectedCells[id].cell = cell;
                let color = colorControl.SELECTED_CELL_COLOR;
                this.ChartsData.selectedCells[id].cell.setStyle({color: color, weight: 4, opacity: 1, fillOpacity: 0.2});
                this.ChartsData[scope][field].lg.removeLayer(cell);
                cell.addTo(this.ChartsData.selectedCells.lg);
                delete this.ChartsData[scope][field][id];
            }
        }
        colorControl.syncSelectedStatus(id, true, cell, false)

    }

    match(id) {
        let f = null;
        let s = null
        let cell = null;
        Object.keys(this.ChartsData).forEach(scope => {
            Object.keys(this.ChartsData[scope]).forEach(field => {
                if (scope != 'selectedCells') {
                    if (this.ChartsData[scope][field][id]) {
                        // console.log(this.ChartsData[scope][field][id], field)
                        f = field;
                        s = scope;
                        cell = this.ChartsData[scope][field][id].cell
                    }
                } else if (scope == 'selectedCells') {
                    console.log("a4")

                    if (this.ChartsData.selectedCells[id]) {
                        console.log(id, 'a4')
                        s = scope;
                        f = this.matchFieldBySize(this.ChartsData.selectedCells[id].cell);
                        cell = this.ChartsData.selectedCells[id].cell
                    }
                }
            })
        })
        return {scope: s, field: f, cell: cell};
    }

    dispatchSelectedCells() {
        console.log("----------")
        console.log("----------")
        console.log("----------")
        console.log("----------")
        this.setMultiTiles();
        console.log(file, 'dispatching selected cells: start')
        let s = this.getSelectedCells()
        if (s.length > 0) store.dispatch('addS63Items', s)
        console.log(file, 'dispatching selected cells: end')
    }

    onGetId(id) {
        let m = this.match(id);
        let field = m.field;
        let scope = m.scope;
        if (field && scope) {
            this.replaceCell(scope, field, id);
        } else {
            // alert("Cell " + id + " dosen't exist")
            console.log('cell not found', id);
        }
    }

    async onCellClicked(id) {
        console.log("onCellClicked")
        console.log(id, "onCellClicked")

        let groups = store.state.charts.s63.products.multiPart;
        let links = store.state.charts.s63.products.linksToProducts;

        let items = store.state.charts.s63.primar_map_info
        if (!items.find(item => item.id === id)) {
            if (id.trim().length > 0)
                alert("Cell: (" + id + ") is not found")
            eventBus.$emit('cell-not-found', id)
        }


        if (groups[links[id]]) {
            let arr = groups[links[id]].productCellList.productCells;
            arr.forEach(key => {
                // console.log(key, id)
                this.onGetId(key.id);
            })
        } else {
            this.onGetId(id)
        }


    }

    matchFieldBySize(cell) {
        let ne = cell.getBounds()._northEast
        let sw = cell.getBounds()._southWest

        let d = Math.sqrt((Math.pow((ne.lat - sw.lat), 2) + Math.pow((ne.lng - sw.lng), 2)));
        if (d >= 80) {
            return 'bigCells';
        } else if (d >= 30) {
            return 'midCells';
        } else if (d > 0) {
            return "lilCells";
        }
        return null;
    }

    clearCellslayer() {
        this.refreshLayers();
        this.refreshData();
    }

    rmChartLayers() {
        Object.keys(this.ChartsLayers).forEach(cl => {
            this.ChartsLayers[cl].removeFrom(this.map);
        });
        this.layersControl.remove();
    }

    addChartLayers() {
        // this.ChartsLayers.overview.addTo(this.map);
        // this.ChartsLayers.general.addTo(this.map);
        // this.ChartsLayers.coastal.addTo(this.map);
        // this.ChartsLayers.harbour.addTo(this.map);
        // this.ChartsLayers.approach.addTo(this.map);
        // this.ChartsLayers.berthing.addTo(this.map);
        // alert(3)
        this.ChartsLayers.unavailable.addTo(this.map);
        this.rmChartLayers()



        if (!CURRENT_PRODUCT.is_navicom) //TODO control
            this.layersControl = L.control.layers({}, this.overlayMaps, {collapsed: true}).addTo(this.map);
        // this.ChartsLayers.general.addTo(this.map);
        // this.ChartsLayers.overview.addTo(this.map);
        this.ChartsLayers.unavailable.addTo(this.map);
        this.scaleCharts()
    }

    isDefaultTiles(flag) {
        this.is_default_tiles = flag;
    }

    // changeTiles(URL){
    //     this.mainLayer.remove();
    //     this.mainLayer = L.tileLayer(URL);
    //     this.mainLayer.addTo(this.map);
    // }
    // setDefaultTiles(){
    //     if (!this.is_default_tiles){
    //         // this.layersControl.remove();
    //         console.log('setDefaultTiles', file)
    //         this.changeTiles("https://api.probki.net/map/{z}/{x}/{y}");
    //         // this.addChartLayers();
    //         this.osmLayer.remove();
    //         this.isDefaultTiles(true);
    //     }
    // }
    // setSpecialTiles(URL){
    //     // this.layersControl.remove();
    //     this.osmLayer.addTo(this.map);
    //     this.changeTiles(URL);
    //     // this.rmChartLayers();
    //     this.isDefaultTiles(false);
    //     // this.layersControl = L.control.layers(
    //     //     {osmLayer: this.osmLayer, mainLayer: this.mainLayer},
    //     //     this.overlayMaps, {collapsed: false})
    // }
    static getSpecialTileLayer(id, bounds) {
        const url = 'https://win1hetz.bgeo.fi:5005/' + id + '/{z}/{x}/{y}';
        // const minZ = this.getMinZFromUsageBand(type);

        const tileLayer = L.tileLayer(url, {
            minZoom: 2,
            maxZoom: 20,
            bounds: bounds,
        })
        return tileLayer;
    }

    getMinZFromUsageBand(chartType) {
        let minZ = 9
        switch (chartType) {
            case 'overview':
                minZ = 9;
                break;
            case 'general':
                minZ = 11;
                break;
            case 'coastal':
                minZ = 13;
                break;
            case 'approach':
                minZ = 15;
                break;
            case 'harbour':
                minZ = 16;
                break;
            case 'berthing':
                minZ = 18;
                break;
        }
        return minZ
    }
    getMaxZFromUsageBand(chartType) {
        let minZ = 9
        switch (chartType) {
            case 'overview':
                minZ = 11;
                break;
            case 'general':
                minZ = 13;
                break;
            case 'coastal':
                minZ = 15;
                break;
            case 'approach':
                minZ = 16;
                break;
            case 'harbour':
                minZ = 18;
                break;
            case 'berthing':
                minZ = 21;
                break;
        }
        return minZ
    }

    setMultiTiles(remove = false) {
        // this.updateMultiTiles();
        // if (this.multiTilesStateControl._currentState.stateName === 'on'){
        //     this.clearMultiTiles();
        //     if (!remove) this.addMultiTiles()
        // }
    }

    clearMultiTiles() {
        if (this.tilesGroup) {
            Object.keys(this.tilesGroup).forEach(type => {
                this.tilesGroup[type].forEach(tile => {
                    tile.remove();
                })
            })
        }
    }

    addMultiTiles() {
        this.mainLayer.remove();
        this.osmLayer.remove();
        this.osmLayer.addTo(this.map);
        this.isDefaultTiles(false);
        Object.keys(this.tilesGroup).forEach(type => {
            console.log(this.tilesGroup[type], type);
            this.tilesGroup[type].forEach(TL => {
                TL.addTo(this.map)
            })
        })
    }

    updateMultiTiles() {
        this.tilesGroup =
            {
                overview: [],
                general: [],
                coastal: [],
                approach: [],
                harbour: [],
                berthing: [],
            };
        // console.log(this.ChartsLayers, this.ChartsData)
        Object.keys(this.ChartsData.unavailable).forEach(level => {
            Object.keys(this.ChartsData.unavailable[level]).forEach(id => {
                if (id !== 'lg') {
                    const cell = this.ChartsData.unavailable[level][id].cell;
                    const lefId = Object.keys(cell._layers)[0];
                    const type = cell._layers[lefId].feature.properties.type;
                    const bounds = cell.getBounds()
                    const TL = ChartsLeaf.getSpecialTileLayer(id, bounds);
                    this.tilesGroup[type].push(TL);
                }
            })
        })

        Object.keys(this.ChartsData.selectedCells).forEach(id => {
            if (id !== 'lg') {
                console.log("a5")
                const cell = this.ChartsData.selectedCells[id].cell;
                const lefId = Object.keys(cell._layers)[0];
                const type = cell._layers[lefId].feature.properties.type;
                const bounds = cell.getBounds()
                const TL = ChartsLeaf.getSpecialTileLayer(id, bounds);
                this.tilesGroup[type].push(TL);
            }
        })
    }

    redrawMap(URL) {
        this.setCeatalogueNotHistory(false);
        console.log(URL)
        this.mainLayer.remove();
        //this.map.setView(coordinates, zoom_level);

        // map.setMaxZoom(maxzoom);
        // map.setMinZoom(minzoom);
        this.map.setMaxZoom(20);
        this.map.setMinZoom(2);
        this.map.setZoom(4)


        let osmLayer = L.tileLayer("https://api.probki.net/map/{z}/{x}/{y}");
        this.layersControl.remove()
        this.mainLayer = L.tileLayer(URL);
        this.layersControl = L.control.layers({osmLayer: osmLayer, mainLayer: this.mainLayer}).addTo(this.map);


        osmLayer.addTo(this.map);
        this.mainLayer.addTo(this.map);

    }
}