<template>
  <div>

    <b-modal v-model="showCalculateModal" title="Auto routing" @ok="okPressed" @cancel="cancelPressed">
      <p>Do you want to calculate route from {{ this.routePorts[this.PORT_TYPES.START] }} to
        {{ this.routePorts[this.PORT_TYPES.FINISH] }}?</p>
    </b-modal>

    <b-container fluid>
      <b-row>
        <b-col>
          <b-form-group>
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="searchFilter"
                  debounce="200"
                  type="search"
                  placeholder="Type to Search"
                  @input="onStartFilter"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="light" :disabled="!searchFilter" @click="searchFilter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-show="!busyFlag">
        <b-row>
          <b-col>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table selectable select-mode="single" primary-key="name" ref="searchOnTable" :per-page="perPage"
                     :current-page="currentPage" :items="ports" :fields="visible_fields"
                     @row-selected="onRowSelected" @filtered="onFiltered">

              <template #cell(start)="row">
                <div
                    v-if="checkPort(row) && !routePorts[PORT_TYPES.START] && routePorts[PORT_TYPES.FINISH] !== row.item.name">
                  <b-button size="sm" variant="success" @click="addPort(PORT_TYPES.START, row)">
                    <b-icon icon="flag"></b-icon>
                  </b-button>
                </div>
                <div v-else>
                  <b-button v-if="routePorts[PORT_TYPES.START] === row.item.name" @click="removePort(PORT_TYPES.START)"
                            size="sm">
                    <b-icon icon="flag"></b-icon>
                  </b-button>
                </div>
              </template>

              <template #cell(finish)="row">
                <div
                    v-if="checkPort(row) && !routePorts[PORT_TYPES.FINISH] && routePorts[PORT_TYPES.START] !== row.item.name">
                  <b-button size="sm" variant="warning" @click="addPort(PORT_TYPES.FINISH, row)">
                    <b-icon icon="flag"></b-icon>
                  </b-button>
                </div>
                <div v-else>
                  <b-button v-if="routePorts[PORT_TYPES.FINISH] === row.item.name"
                            @click="removePort(PORT_TYPES.FINISH)" size="sm">
                    <b-icon icon="flag"></b-icon>
                  </b-button>
                </div>
              </template>

            </b-table>
          </b-col>
        </b-row>
      </div>
      <div align="center" class="text-sm-centercenter text-danger my-2" v-show="busyFlag">
        <b-spinner small class="align-middle"></b-spinner>
        <strong>&nbsp;Searching...</strong>
      </div>
    </b-container>
  </div>
</template>

<script>
import {eventBus} from "@/main";
import store from "@/store";
import Vue from "vue";

let portsJSON = require("@/data/ports.json")

export default {
  name: "Search",
  data() {
    return {
      ports: portsJSON,
      busyFlag: false,
      searchFilter: '',
      aaa: null,
      filterOn: ['name'],
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
      routePorts: ["", ""],
      routePortsCoordinates: [null, null],
      PORT_TYPES: {START: 0, FINISH: 1},
      showCalculateModal: false
    }
  },
  mounted() {
    this.totalRows = this.ports.length

    eventBus.$off('port-clicked')
    eventBus.$on('port-clicked', (name) => {
      this.searchFilter = name
    })
  },
  methods: {
    onStartFilter() {
    },
    onRowSelected(items) {
      let lat = items[0].lat
      let lon = items[0].lon
      eventBus.$emit('show-from-search', {lat: lat, lon: lon})
      this.$refs.searchOnTable.clearSelected()
      this.$root.$emit('bv::toggle::collapse', 'search-sidebar')
    },
    onFiltered(filteredItems) {
      this.totalRows = this.ports.length
      this.currentPage = 1
      this.busyFlag = false;
    },
    removeSearchFilter() {
      this.searchFilter = ''
    },
    addPort(portType, row) {
      let lat = row.item.lat
      let lon = row.item.lon
      eventBus.$emit('show-from-search', {lat: lat, lon: lon})

      Vue.set(this.routePorts, portType, row.item.name)
      Vue.set(this.routePortsCoordinates, portType, {lat: row.item.lat, lon:row.item.lon})
      this.removeSearchFilter()
    },
    removePort(portType) {
      Vue.set(this.routePorts, portType, "")
      Vue.set(this.routePortsCoordinates, portType, null)
      this.removeSearchFilter()
    },
    checkPort(row) {
      let port = row.item.name

      if (!this.routePorts[this.PORT_TYPES.START] && port !== this.routePorts[this.PORT_TYPES.START] ||
          !this.routePorts[this.PORT_TYPES.FINISH] && port !== this.routePorts[this.PORT_TYPES.FINISH])
        return true
      else
        return false
    },
    okPressed() {
      eventBus.$emit('show-waypoints')
      this.removeSearchFilter()
      this.calculateGlobalRoute()
      eventBus.$emit('draw-start-finish', this.routePortsCoordinates[0], this.routePortsCoordinates[1])
    },
    cancelPressed() {
      this.routePorts = ["", ""]
      this.searchFilter = ''
    },
    calculateGlobalRoute() {
        store.commit("fickle/setGlobalRoute", {ports:this.routePorts, coordinates:this.routePortsCoordinates})
        this.routePorts = ["", ""]
    }
  },
  computed: {
    visible_fields() {
      return [
        {key: 'name', label: 'Name', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'start', label: 'Start', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'finish', label: 'Finish', tdClass: 'small-black', thClass: 'small-gray'}
      ]
    }
  },
  watch: {
    'searchFilter'(newVal) {
      if (newVal.length > 0) {
        this.ports = portsJSON.filter(function (e) {
          // return e.name.includes(newVal.toUpperCase())
          return e.name.startsWith(newVal.toUpperCase())
        })
        this.totalRows = this.ports.length
      } else {
        this.ports = portsJSON
        this.totalRows = this.ports.length
      }
    },
    'routePorts'() {
      if (this.routePorts[this.PORT_TYPES.START] && this.routePorts[this.PORT_TYPES.FINISH])
        this.showCalculateModal = true
    },
  }
}
</script>