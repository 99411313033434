<template>
  <div>
    <b-modal v-model="is_route_error" title="Route calculating error" ok-only>
      <div v-html="route_error_text"/>
    </b-modal>

    <b-modal v-model="is_show_map_info" title="Map info" scrollable modal-class="myclass" draggable="false" ok-only>
      <div v-html="map_info_text"/>
    </b-modal>

    <h6 align="center" class="mt-2">ROUTES</h6>

    <div id="mymap" ref="mm" :style="{ height: height + 'px'}"></div>
<!--    <b-overlay :show="$store.state.routes.progress.isCalculating" rounded="sm">-->
<!--      <div id="mymap" ref="mm" :style="{ height: height + 'px'}"></div>-->

<!--      <template #overlay>-->
<!--        <div class="text-center">-->
<!--          <p id="cancel-label">Route/Safety calculating, please wait...</p>-->

<!--          <b-progress class="mt-2" :max=100 show-value show-progress animated>-->
<!--            <b-progress-bar :value="$store.state.routes.progress.calculatingProgress"-->
<!--                            :label="`${($store.state.routes.progress.calculatingProgress)}%`"-->
<!--                            variant="primary"></b-progress-bar>-->
<!--          </b-progress>-->

<!--          <b-button class="mt-2" size="sm" @click="cancelRoute()" variant="danger">Cancel</b-button>-->
<!--        </div>-->
<!--      </template>-->
<!--    </b-overlay>-->
  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  props: ["map", "height"],
  data() {
    return {
      is_route_error: false,
      is_show_map_info: false,
      route_error_text: '',
      map_info_text: ''
    }
  },

  mounted() {
    this.map.init(this.$refs.mm);

    eventBus.$off('show-route-error')
    eventBus.$on('show-route-error', (text) => {
      this.route_error_text = text
      this.is_route_error = true
    })

  },
  beforeDestroy() {
    eventBus.$emit('cancel-auto-route')
  },
  methods: {
    cancelRoute() {
      eventBus.$emit('cancel-auto-route')
    }
  }
}
</script>

<style scoped>
#mymap {
  z-index: 1;
}

/deep/ .my-class {
  background: black;
  color: white;
}

/deep/ .myclass > div {
  position: absolute !important;
  top: -10px !important;
  left: 10px !important;
  background-color: #77ccdd !important;
}

/deep/ .myclass > .modal-dialog > .modal-content {
  background-color: #77ccdd !important;
}

.leaflet-grab {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab {
  cursor: move;
}
</style>
