import { defineStore } from 'pinia'

export const useMomentaryStore = defineStore({
    id: 'momentary',
    state: () => ({
        route: {
            chartsbyroute: [],
            chartsbylegs: [],
            pubsbylegs: []

        }
    }),
    actions: {
        addChartsByRoute(val) {
            this.route.chartsbyroute = val
        },
        addChartsByLegs(val) {
            this.route.chartsbylegs = val
        },
        addPubsByLegs(val) {
            this.route.pubsbylegs = val
        },
        clearChartsByRoute() {
            this.route.chartsbyroute = []
        },
        clearChartsByLegs() {
            this.route.chartsbylegs = []
        }
    },
    persist: false
})
