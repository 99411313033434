<template>
  <div>
    <b-card v-if="$store.state.routes.route.length > 0 && zoneInfo === ''" no-body bg-variant="light" class="mt-2 pl-3">
      <b-row>
        <b-col>
          <span class="smt2s">Start time: {{ routeTime() }}</span><br>
          <span class="smt2s">ETA: {{
              datem($store.state.routes.info[$store.state.routes.route.length - 1].fromStartSeconds)
            }}</span><br>
        </b-col>
        <b-col>
          <span class="smt2s">Total waypoints: {{ $store.state.routes.route.length }}</span><br>
          <span class="smt2s">Total length: {{
              ($store.state.routes.info[$store.state.routes.route.length - 1].fromStartMeters * 0.000539957).toFixed(1)
            }} nm</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="zoneInfo !== '' && $store.state.routes.route.length > 0" no-body bg-variant="light" class="mt-2 pl-3">
      <b-row>
        <b-col>
          <div v-html="zoneInfoTitle"/>
        </b-col>
        <b-col>
          <b-button class="border-0 float-right" size="sm" variant="outline-danger" @click="clearZoneInfo()">
            <b-icon icon="x" font-scale="1.5"></b-icon>
          </b-button>
        </b-col>
      </b-row>
      <div v-html="zoneInfo"/>
    </b-card>

    <b-table sticky-header select-mode="single" ref="wptable" id="routes-table" style="max-height: 400px;"
             @row-selected="onRowSelected" @row-clicked="onRowClicked" :items="items" :fields="visible_fields"
             :tbody-tr-class="rowClass" class="mt-2">

      <template #cell(selected)="data">
        <template v-if="data.index === currentRowIndex">
          <b-icon icon="play-fill"></b-icon>
        </template>
      </template>

      <template #cell(name)="data">
        <span v-if="data.index === currentRowIndex" class='smt2s'>
          <b><span class='smt2s'>
              <a class="blackref" href="#" @click='showLeg(data.item, data.index)'>{{
                  data.index
                }} </a>
            </span>

            <!--          <span aria-hidden="true">&check;</span>-->
            <!--          <span aria-hidden="true">&check;</span>-->
            <!--            <b-icon icon="play"></b-icon>-->
            <!--          <span class="sr-only">Selected</span>-->
            </b>

        </span>
        <span v-else class='smt2s'>
              <a class="blackref" href="#" @click='showLeg(data.item, data.index)'>{{
                  data.index
                }} </a>
            </span>
      </template>

      <!--      <template #cell(safety)="data">-->
      <!--        <span class='smt2s'>{{ getSafety(data.index) }}</span>-->
      <!--      </template>-->

      <!--      <template #cell(warnings)="data">-->
      <!--        <span class='smt2s'>{{ getWarnings(data) }}</span>-->
      <!--      </template>-->

      <template #cell(lat)="row">
          <span v-if="row.index === currentRowIndex" class='smt2s'>
            <b><a class="blackref" href="#" @click='showLeg(row.item, row.index)'>
              {{ row.item.lat.toFixed(4) }}<br>{{ row.item.lon.toFixed(4) }}</a>
            </b>
          </span>
        <span v-else class='smt2s'>
            <a class="blackref" href="#" @click='showLeg(row.item, row.index)'>
              {{ row.item.lat.toFixed(4) }}<br>{{ row.item.lon.toFixed(4) }}</a>
          </span>
      </template>

      <template #cell(speed)="data">
        <!--        <b-form-input v-if="items[data.index].isEdit && selectedCell === 'speed'" type="text"-->
        <!--                      v-model="items[data.index].speed"></b-form-input>-->
        <!--        <span v-else class='smt2s' @click="editCellHandler(data, 'speed')">-->
        <!--            {{ items[data.index].speed }} kn-->
        <!--          </span>-->
        <span v-if="data.index === currentRowIndex" class='smt2s'>
          <b>{{ items[data.index].speed }} kn</b>
        </span>
        <span v-else class='smt2s'>
            {{ items[data.index].speed }} kn
        </span>
      </template>

      <template #cell(eta)="data">
        <div v-if="data.index === currentRowIndex">
          <b>
          <span class='smt2s' v-if="$store.state.routes.info.length > 0 && $store.state.routes.info[data.index]">
            <span v-if="routesStore.routeSettings.startdate === '' || routesStore.routeSettings.starttime === ''">
              {{
                new Date(($store.state.routes.info[data.index].fromStartSeconds ?? 0) * 1000).toISOString().substring(11, 16)
              }}
            </span>
            <span v-else>
              {{ datem($store.state.routes.info[data.index].fromStartSeconds) }}
            </span>
          </span>
          </b>
        </div>
        <div v-else>
          <span class='smt2s' v-if="$store.state.routes.info.length > 0 && $store.state.routes.info[data.index]">
            <span v-if="routesStore.routeSettings.startdate === '' || routesStore.routeSettings.starttime === ''">
              {{
                new Date(($store.state.routes.info[data.index].fromStartSeconds ?? 0) * 1000).toISOString().substring(11, 16)
              }}
            </span>
            <span v-else>
              {{ datem($store.state.routes.info[data.index].fromStartSeconds) }}
            </span>
          </span>
        </div>
      </template>

      <template #cell(dangers)="data">
        <br><br>
        <span class='smt2s'>
            <div v-for="(item) in getDangers(data.index)" class="mt-2">
              <a href="#" @click='showZone(data.index, item.id, item.points, item.type)'>{{
                  item.name
                }} </a>
              <!--                <span v-if="item.type !== 'area'">({{ item.type }})</span></a>-->
            </div>
          </span>
      </template>

      <template #cell(length)="data">
        <div v-if="data.index === currentRowIndex">
          <b>
          <span class='smt2s' v-if="$store.state.routes.info.length > 0 && $store.state.routes.info[data.index]">
            <div v-if="$store.state.routes.info[data.index].fromPreviousMeters < 1000">
              {{ ($store.state.routes.info[data.index].fromPreviousMeters).toFixed(1) }}m,
            </div>
            <div v-else>
              {{ ($store.state.routes.info[data.index].fromPreviousMeters * 0.000539957).toFixed(1) }}nm,
            </div>
            {{ ($store.state.routes.info[data.index].fromStartMeters * 0.000539957).toFixed(1) }}nm
          </span>
          </b>
        </div>
        <div v-else>
          <span class='smt2s' v-if="$store.state.routes.info.length > 0 && $store.state.routes.info[data.index]">
            <div v-if="$store.state.routes.info[data.index].fromPreviousMeters < 1000">
              {{ ($store.state.routes.info[data.index].fromPreviousMeters).toFixed(1) }}m,
            </div>
            <div v-else>
              {{ ($store.state.routes.info[data.index].fromPreviousMeters * 0.000539957).toFixed(1) }}nm,
            </div>
            {{ ($store.state.routes.info[data.index].fromStartMeters * 0.000539957).toFixed(1) }}nm
          </span>
        </div>
      </template>
    </b-table>
    <!--    </b-overlay>-->
  </div>
</template>

<script>
import {eventBus} from "@/main";
import {useRoutesStore} from "@/stores/routes";
import {useMomentaryStore} from "@/stores/momentary";
import * as dayjs from 'dayjs/dayjs.min'
import {mapStores} from "pinia";
import {createVoyagePlan} from "@/views/routes/components/VoyagePlan";
import {myAxios, myAxiosRetry} from "@/services/myAxios";
import {file} from "paths.macro";
import router from "@/router";
import logger from "@fortawesome/vue-fontawesome/src/logger";

let customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export default {
  name: "RouteTable",
  props: {
    fields: Array
  },
  data() {
    return {
      spinner: false,
      prevRowIndex: -1,
      currentRowIndex: -1,
      centerRowIndex: -1,
      focusWPIndex: -1,
      isShowSearch: false,
      searchFilter: null,
      selectedCell: null,
      zoneInfoTitle: "",
      zoneInfo: ""
    }
  },

  computed: {
    ...mapStores(useRoutesStore, useMomentaryStore),
    // ...mapStores(useMomentaryStore),
    visible_fields() {
      return [
        {key: 'selected', label: '', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'name', label: '#', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'safety', label: 'D', tdClass: 'small-black', thClass: 'small-gray'},
        // {key: 'warnings', label: 'W', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'lat', label: 'Lat/Lon', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'dangers', label: 'Hazards', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'speed', label: 'Speed', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'eta', label: 'ETA', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'length', label: 'Length', tdClass: 'small-black', thClass: 'small-gray'},
        {key: 'edit', label: ''}
      ]
    },
    items() {
      return this.$store.state.routes.route
    },
    getHazards() {
      const hazards = this.$store.state.routes.dangers.filter(function (e) {
        return (e.isDanger ||
            (!e.isDanger && e.name !== "Depth contour" && e.name !== "Depth area")

            // (e.isDanger === false && e.name === "Traffic separation zone") ||
            // (e.isDanger === false && e.name === "Inshore traffic zone") ||
            // (e.isDanger === false && e.name === "Caution area") ||
            // (e.isDanger === false && e.name === "Offshore production area") ||
            // (e.isDanger === false && e.name === "Sea-plane landing area") ||
            // (e.isDanger === false && e.name === "Submarine transit lane") ||
            // (e.isDanger === false && e.name === "Anchorage area") ||
            // (e.isDanger === false && e.name === "Marine farm/culture") ||
            // (e.isDanger === false && e.name === "Military practice area") ||
            // (e.isDanger === false && e.name.startsWith("Restricted area"))
        )
      })

      return hazards
    }
  },
  watch: {},
  mounted() {
    eventBus.$off('select-routetable-row')
    eventBus.$off('set-center-index')
    eventBus.$off('focus-center-index')
    eventBus.$off('move-current')
    eventBus.$off('move-next')
    eventBus.$off('move-prev')
    eventBus.$off('clear-current-wp-index')

    eventBus.$on('clear-current-wp-index', () => {
      this.currentRowIndex = -1
      eventBus.$emit('show-wp-index', this.currentRowIndex)
      eventBus.$emit('clear-current-wp-index-weather')
      eventBus.$emit('clear-current-wp-index-zones')
    })

    eventBus.$on('move-current', () => {
      eventBus.$emit('move-current-weather')
      eventBus.$emit('move-current-zones')
      if (this.prevRowIndex !== -1) {
        this.currentRowIndex = this.prevRowIndex
        this.selectRow(this.currentRowIndex)
      }
    })

    eventBus.$on('move-next', () => {
      console.log("qqq", this.currentRowIndex)

      eventBus.$emit('move-next-weather')
      eventBus.$emit('move-next-zones')
      if (this.currentRowIndex !== -1) {
        this.currentRowIndex = this.currentRowIndex === this.$store.state.routes.route.length - 1 ? 0 : this.currentRowIndex + 1
        this.prevRowIndex = this.currentRowIndex
        this.selectRow(this.currentRowIndex)
      } else {
        this.selectRow(this.focusWPIndex)
      }

      eventBus.$emit('switch-popup', this.currentRowIndex)
      eventBus.$emit('show-dangers-in-wp', this.currentRowIndex)
    })

    eventBus.$on('move-prev', () => {
      eventBus.$emit('move-prev-weather')
      eventBus.$emit('move-prev-zones')
      if (this.currentRowIndex !== -1) {
        this.currentRowIndex = this.currentRowIndex === 0 ? this.$store.state.routes.route.length - 1 : this.currentRowIndex - 1
        this.prevRowIndex = this.currentRowIndex
        this.selectRow(this.currentRowIndex)
      } else {
        this.selectRow(this.focusWPIndex)
      }

      eventBus.$emit('switch-popup', this.currentRowIndex)
      eventBus.$emit('show-dangers-in-wp', this.currentRowIndex)
    })

    eventBus.$on('set-center-index', (index) => {
      this.centerRowIndex = index
    })

    eventBus.$on('set-focus-index', (index) => {
      this.focusWPIndex = index
      eventBus.$emit('set-focus-index-weather', index)
      eventBus.$emit('set-focus-index-zones', index)
    })

    eventBus.$on('select-routetable-row', (id) => {
      eventBus.$emit('select-routetable-row-weather', id)
      eventBus.$emit('select-routetable-row-zones', id)
      this.currentRowIndex = id
      this.$refs.wptable.selectRow(id);
      const tbody = this.$refs.wptable.$el.querySelector('tbody')
      const row = tbody.querySelectorAll('tr')[id]
      row.scrollIntoView({block: 'center'})
      eventBus.$emit('show-wp-index', id)
    })
  },
  methods: {

    // getSafety(index) {
    //   let safety = this.routesStore.routeLegs[index].safety
    //   return safety
    // },
    // getWarnings(data) {
    //   let warning = data.item.isDanger
    //   return warning
    // },

    createPDF() {

      let points = []

      this.$parent.$parent.$parent.$parent.loadingFlag = true

      this.routesStore.route.forEach(item => {
        points.push({lat: item.lat, lon: item.lon})
      })

      return new Promise(resolve => {
        myAxiosRetry.post("api/v1/mss/mapswithlegs", points
        ).then(resp => {
          this.momentaryStore.addChartsByLegs(resp.data)

          myAxiosRetry.post("api/v1/mss/adpenp", points
          ).then(resp => {
            console.log("dddd", resp.data)
            this.momentaryStore.addPubsByLegs(resp.data)

            this.$parent.$parent.$parent.$parent.loadingFlag = false
            createVoyagePlan()
            resolve()
          })
        });
      })


    },
    async sendRouteToOrder() {
      let points = []

      this.$parent.$parent.$parent.$parent.loadingFlag = true
      // this.$parent.$parent.loadingFlag = true

      this.routesStore.route.forEach(item => {
        points.push({lat: item.lat, lon: item.lon})
      })

      return new Promise(resolve => {
        myAxiosRetry.post("api/v1/mss/maps", points
        ).then(resp => {
          this.momentaryStore.addChartsByRoute(resp.data)
          router.push({name: "proxy-orders"});
          resolve()
        });
      })

      // await this.onLoadingFlag();
      // setTimeout(() => {
      //   this.$store.commit("routes/useRouteForOrder", true)
      //   eventBus.$emit('send-order-route')
      // }, 600)
    },
    rowClass(item, type) {
      if (!item.safety === 2 || type !== 'row') return
      if (item.safety === 2)
        return 'table-danger'
      else if (item.isDanger)
        return 'table-warning'

      // if (!item.isDanger || type !== 'row') return
      // if (item.isDanger) return 'table-danger'

      // let rowClass = ''
      // let routeLegs = this.routesStore.routeLegs
      // if (routeLegs[item.legId].safety === 2)
      //   rowClass = 'table-danger'
      //
      // return rowClass


    },
    editCellHandler(data, name) {
      this.items[data.index].isEdit = true;
      this.selectedCell = name
    },
    getDangers(index) {

      const dangers = this.getHazards

      // const dangers = this.$store.state.routes.dangers.filter(function (e) {
      //   return (e.isDanger === true ||
      //       (e.isDanger === false && e.name === "Traffic separation zone") ||
      //       (e.isDanger === false && e.name === "Inshore traffic zone") ||
      //       (e.isDanger === false && e.name === "Caution area") ||
      //       (e.isDanger === false && e.name === "Offshore production area") ||
      //       (e.isDanger === false && e.name === "Sea-plane landing area") ||
      //       (e.isDanger === false && e.name === "Submarine transit lane") ||
      //       (e.isDanger === false && e.name === "Anchorage area") ||
      //       (e.isDanger === false && e.name === "Marine farm/culture") ||
      //       (e.isDanger === false && e.name.startsWith("Restricted area"))
      //   )
      // })

      const legDangers = dangers.filter(function (e) {
        return e.legIndex === index + 1
      })

      let items = []
      legDangers.forEach(item => {
        items.push({name: item.name, type: item.locationType, points: item.points, id: item.id})
      })

      return items
    },
    showZone(index, id, points, type) {
      eventBus.$emit('show-dangers-in-wp', index)
      myAxios.get(`https://mwend.bgeo.fi/api/v1/objectinfo?id=${id}`).then(resp => {
        this.showZoneInfo(resp.data)
        eventBus.$emit("draw-object", {lines: resp.data.lines, type: type, zoom: true})
      });
    },
    showZoneInfo(data) {
      this.zoneInfoTitle = "<b><span class='smt2s'>" + data.name + "</span></b>"
      let text = ""
      data.attributes.forEach((item) => {
        if (item.name !== "Textual description")
          text += "<span class='smt2s'>" + item.name + ": " + item.value + "</span><br>"
        else {
          text += "<span class='smt2s'><details><summary>" + item.name + "...</summary>" + item.value + "</span></details><br>"
        }
      })

      this.zoneInfo = text
    },
    clearZoneInfo() {
      this.zoneInfoTitle = ""
      this.zoneInfo = ""
      eventBus.$emit('clear-object')

    },
    routeTime() {
      let datetime = dayjs(this.routesStore.routeSettings.startdate + " " + this.routesStore.routeSettings.starttime).format("DD MMM HH:mm")
      if (datetime === "Invalid date")
        datetime = "N/A"
      return datetime
    },
    datem(seconds) {
      let datetime = dayjs(this.routesStore.routeSettings.startdate + " " + this.routesStore.routeSettings.starttime)
      let datetimenew = datetime.add(seconds, "seconds")
      let shortdate = datetimenew.format("DD/MM HH:mm")
      return shortdate
    },
    selectRow(index) {
      this.$refs.wptable.unselectRow(index);
      setTimeout(() => {
        this.$refs.wptable.selectRow(index);
      }, 100)
      this.currentRowIndex = index

      eventBus.$emit('click-wp', index)
    },
    showLeg(item, index) {
      console.log("aaaa", item)
      console.log("aaaa", index)
      this.onRowClicked(item, index)
    },
    onRowClicked(row, index) {
      this.clearZoneInfo()
      // alert(1)

      eventBus.$emit('close-popup')
      this.items.forEach((item, i) => {
        item.isEdit = false
      })
      this.currentRowIndex = index
      eventBus.$emit('click-wp', index, true)
      eventBus.$emit('show-dangers-in-wp', index)
      console.log("aaaaa1", row)
      eventBus.$emit('wp-selected', row)
    },
    // onRowSelected(items) {
    //   // alert(2)
    //   console.log("aaaaa2", items[0])
    //   // eventBus.$emit('wp-selected', items[0])
    // },
  }
}
</script>

<style>
.btn-block-50 {
  display: block;
  width: 50%;
}
</style>

<style scoped>
/deep/ .table > tbody > tr.b-table-row-selected,
/deep/ .table > tbody > tr.b-table-row-selected > td,
/deep/ .table > tbody > tr.b-table-row-selected > th {
  background-color: rgba(124, 124, 124, 0.1);
  //border:1px solid red;
}
.blackref {
  color: dimgray;
}

</style>