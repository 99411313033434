<template>
  <div>
    <div v-if="!is_mobile">
      <b-modal id="modal-payment-warning" title="Warning" v-model="openPaymentWarning" ok-only @ok="goTransactions">Agreement payment isn't realised yet. Select device without Agreement</b-modal>

      <!--      <b-modal title="List of cells" v-model="$parent.openCellsList" ok-only @ok="primarMapBoosterByList">-->
      <!--        <b-form-textarea v-model="cellList" placeholder="Past comma or new line separated cells names..."></b-form-textarea>-->
      <!--      </b-modal>-->

      <b-row>
        <b-col>
          <b-alert variant="warning" show>
            Primar (ENC)
          </b-alert>
        </b-col>
      </b-row>

      <div class="small-black" v-if="$store.state.proto.order.step !== ''">
        <b-row v-show="!is_mobile">
          <b-col>
            <span class="small-gray-card">Order</span><br>
            {{ get_order }}
          </b-col>
<!--          <b-col class="text-right">-->
<!--            <div align="center">{{ get_order }}</div>-->
<!--          </b-col>-->
        </b-row>
<!--        <hr v-if="!is_mobile">-->
<!--        <b-row v-if="!$store.state.proto.agreement.buzy && !is_mobile">-->
<!--          <b-col>-->
<!--            My status:-->
<!--          </b-col>-->
<!--          <b-col class="text-right">-->
<!--            Customer-->
<!--          </b-col>-->
<!--        </b-row>-->
        <b-row v-if="$store.state.proto.agreement.buzy && !is_mobile">
          <b-col>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>&nbsp;Loading...</strong>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="!$store.state.proto.agreement.buzy && !is_mobile">
          <b-col>
            <br><span class="small-gray-card">Payment method</span><br>
            {{ payment_system }}
          </b-col>
<!--          <b-col class="text-right">-->
<!--          </b-col>-->
        </b-row>
        <hr v-if="!is_mobile">
        <b-row v-if="currentCart.length > 0 && is_request && !is_mobile" class="mt-2">
          <b-col cols="4">
            Total price:
          </b-col>
          <b-col class="text-right">
            <div v-if="isAllCellsPriceReady()">
              <span v-if="disabledCellsCount() !== currentCart.length">
                <div v-if="!hasValidAgreement">
                  <b>{{ $store.state.current.cart.totalPriceByPrimar }} €</b>
                </div>
                <div v-if="hasValidAgreement">
                  <div v-if="isRUBAgreement()">
                    <b>{{ $store.state.current.cart.totalPriceByPrimarAgreementRUB }} ₽</b>
                  </div>
                  <div v-else>
                    <b>${{ $store.state.current.cart.totalPriceByPrimarAgreementUSD }}</b>
                  </div>
                </div>
                <br>
                Net price: {{ net }} €,&nbsp;
                VAT: {{ vat }} €,&nbsp;
                Fee: {{ fee }} €
              </span>
              <span v-else>
                0 €
              </span>
            </div>
            <div v-else class="text-sm-centercenter text-danger my-2">
              <b-spinner small class="align-middle"></b-spinner>
              <strong>&nbsp;Price calculating...</strong>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="(currentCart.length > 0 || $store.state.proto.order.step === 'placed') && !is_mobile && hasValidAgreement" class="mt-2">
          <b-col>
            Order status:
          </b-col>
          <b-col class="text-right" v-if="this.hasValidAgreement">
            <b-dropdown block size="sm" variant="outline-info" :text="$store.state.proto.order.order_status" :disabled="$store.state.auth.user.role !== 'distributor'">
              <b-dropdown-item @click="setStatus('request')">request</b-dropdown-item>
              <b-dropdown-item @click="setStatus('to pay')">to pay</b-dropdown-item>
              <b-dropdown-item @click="setStatus('cancelled')">cancel</b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col class="text-right" v-else>
          </b-col>
        </b-row>
        <b-row v-if="currentCart.length > 0 && $store.state.proto.order.step !== 'placed' && !is_mobile" class="mt-2">
          <b-col>
            ENC updating period:
          </b-col>
          <b-col class="text-right">
            <b-dropdown block size="sm" variant="outline-info" :text="selectedPeriod.period + ' months'">
              <b-dropdown-item @click="setPeriod(3)">3 months</b-dropdown-item>
              <b-dropdown-item @click="setPeriod(6)">6 months</b-dropdown-item>
              <b-dropdown-item @click="setPeriod(12)">12 months</b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>

        <b-row v-if="$store.state.proto.order.step !== 'placed' && !is_mobile" class="mt-2">
          <b-col>
            <b-button size="sm" variant="info" @click="importOrder()">Import</b-button>&nbsp;
          </b-col>
          <b-col class="text-right">
            <!--          <b-button size="sm" variant="info" @click="cancelOrder()">Cancel</b-button>&nbsp;-->
            <div v-if="currentCart.length > 0 && disabledCellsCount() !== currentCart.length">
              <b-button v-if="$store.state.proto.order.update" size="sm" variant="warning" @click="updateOrder()">Update</b-button>
              <!--            <b-button v-if="$store.state.proto.order.update" size="sm" variant="warning" @click="updateOrderStatus()" :disabled="!isAllCellsPriceReady()">Update</b-button>-->
              <b-button v-else size="sm" variant="success" @click="checkAndPlaceOrder()" :disabled="!isAllCellsPriceReady()">Submit</b-button>
            </div>
          </b-col>
        </b-row>

      </div>

    </div>
    <div v-else-if="is_mobile">
      <div align="center">{{ get_order }} {{payment_system_mobile}}</div>
      <div class="ml-0 mb-1 mx-auto" align="center" v-if="$store.state.proto.order.step !== ''">

        <b-dropdown variant="info" class="ml-1" size="sm" right :text="$store.state.proto.order.order_status" v-if="currentCart.length > 0 || $store.state.proto.order.step === 'placed'" :disabled="!hasValidAgreement">
          <b-dropdown-item @click="setStatus('request')">Status: request</b-dropdown-item>
          <b-dropdown-item @click="setStatus('to pay')">Status: to pay</b-dropdown-item>
          <b-dropdown-item @click="setStatus('cancelled')">Status: to cancel</b-dropdown-item>
        </b-dropdown>

        <b-dropdown variant="info" class="ml-1" size="sm" right :text="selectedPeriod.period + ' months'" v-if="currentCart.length > 0 && $store.state.proto.order.step !== 'placed'">
          <b-dropdown-item @click="setPeriod(3)">Period 3 months</b-dropdown-item>
          <b-dropdown-item @click="setPeriod(6)">Period 6 months</b-dropdown-item>
          <b-dropdown-item @click="setPeriod(12)">Period 12 months</b-dropdown-item>
        </b-dropdown>

        <span v-if="$store.state.proto.order.step !== 'placed' && currentCart.length > 0 && disabledCellsCount() !== currentCart.length &&  isAllCellsPriceReady()">
          <b-button variant="warning" class="ml-1" size="sm" v-if="$store.state.proto.order.update" @click="updateOrder()">

                  <span v-if="!hasValidAgreement">
                    Update: {{ $store.state.current.cart.totalPriceByPrimar }} €
                  </span>
            <span v-if="hasValidAgreement">
                    <span v-if="isRUBAgreement">
                      Update: {{ $store.state.current.cart.totalPriceByPrimarAgreementRUB }} ₽
                    </span>
                    <span v-else>
                      Update: ${{ $store.state.current.cart.totalPriceByPrimarAgreementUSD }}
                    </span>
                  </span>

          </b-button>
          <b-button variant="info" class="ml-1" size="sm" v-else @click="send_pay()">
            Buy for: {{ $store.state.current.cart.totalPriceByPrimar }}€
          </b-button>
        </span>
        <div v-if="!isAllCellsPriceReady() && currentCart.length > 0" class="text-sm-centercenter text-danger my-2">
          <b-spinner small class="align-middle"></b-spinner>
          <strong>&nbsp;Price calculating...</strong>
        </div>

        <b-button class="ml-1" size="sm" v-if="$store.state.proto.order.step === 'placed'" @click="send_pay_ok()">
          Ok
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {daysOldWithSign, formatDate, getAppName, getAppType, getS63CKTInfo, getUUID} from "@/utils/utils";
import store from "@/store";
import {eventBus} from "@/main";
import * as utils from "@/utils/utils";
import {myAxios} from "@/services/myAxios";
import router from "@/router";
import {file} from "paths.macro";

import {CURRENT_PRODUCT} from "@/utils/build-config";
import {payWithStripe} from "@/helpers/pay";

export default {
  name: "Order",
  props: ['selectedPeriod', 'map', 'purchase', 'is_mobile', 'currentCart', 'hasValidAgreement', 'net', 'fee', 'vat'],
  components: {},
  data() {
    return {
      // updateWarning: false,
      openPaymentWarning: false,
      // selectedPeriod: CURRENT_PRODUCT.is_navicom ? "0" : "4",

      stateButtons: [
        {caption: 'request', state: true, variant: "light"},
        {caption: 'order', state: false, variant: "light"},
        {caption: 'to pay', state: true, variant: "light"},
      ],
    }
  },
  watch: {
    //TODO: watch difference between this.$store.state.proto.order and this.$store.state.proto.restoredOrder
    currentCartLen() {
      this.$parent.checkPeriod()
      this.$parent.countTotalPrice();
    },
    currentCart() {
      console.log(this.currentCart, 'ccw')
    },
    '$store.state.proto.agreement.exist': function () {
      if (this.$store.state.proto.agreement && !this.$store.state.proto.agreement.exist && !this.$store.state.proto.agreement.buzy) {
        // alert("to pay")
        this.setStatus('request')
      }

    },
  },
  mounted() {

    eventBus.$on('mobile-period-pressed', (period) => {
      //// alert("child event")
      this.setPeriod(period)
    })
    eventBus.$on('place-order', () => {
      //// alert("child event")
      this.placeOrder()
    })


    if (this.$store.state.proto.order.update && this.$store.state.charts.s63.latest_order.data && !this.is_mobile) {
      //// alert("a")
      if (this.$store.state.charts.s63.latest_order.data.items[0].monthCount === 3) {
        this.setPeriod(3)
      }
      if (this.$store.state.charts.s63.latest_order.data.items[0].monthCount === 6) {
        this.setPeriod(6)
      }
      if (this.$store.state.charts.s63.latest_order.data.items[0].monthCount === 12) {
        this.setPeriod(12)
      }
    }

    if (this.$store.state.proto.order.update && this.$store.state.charts.s63.latest_order.data && this.is_mobile) {
      //// alert("b")
      if (this.$store.state.charts.s63.latest_order.data.items[0].monthCount === 3) {
        this.$parent.setPeriod(3)
      }
      if (this.$store.state.charts.s63.latest_order.data.items[0].monthCount === 6) {
        this.$parent.setPeriod(6)
      }
      if (this.$store.state.charts.s63.latest_order.data.items[0].monthCount === 12) {
        this.$parent.setPeriod(12)
      }
    }


    eventBus.$on('mobile-cancel-pressed', () => {
      this.cancelOrder()
    })
    eventBus.$on('mobile-pay-pressed', () => {
      this.checkAndPlaceOrder()
    })
    eventBus.$on('mobile-pay-ok-pressed', () => {
      this.sendOrder()
    })

    eventBus.$on('ship-selected-from-chart', () => {
      let x = setTimeout(() => {
        // this.$parent.purchase.isPrimarOrderCreating = true;
        this.map.makeChartsSelectable();
        // this.map.selectChartsByRoute();
      }, 1000)
    })
  },
  computed: {
    currentCartLen() {
      return this.$store.state.current.cart.s63cells.length
    },
    get_order() {
      let val = "";
      let typeCode = this.$store.state.proto.order.application_id
      let typeName = getAppName(typeCode)

      if (this.$store.state.proto.order.order_number) {
        if (typeCode === 11) {
          if (this.$store.state.proto.order.ecdis_name) {
            val = this.$store.state.proto.order.order_date + ":" + this.$store.state.proto.order.ecdis_name
          } else {
            val = this.$store.state.proto.order.order_date + ": NONAME"
          }
        } else {
          val = this.$store.state.proto.order.order_date + ":" + this.$store.state.proto.order.device_id
          if (this.$store.state.proto.order.ecdis_name) {
            val += ", ship: " + this.$store.state.proto.order.ecdis_name
          }
        }
      }
      return val;
    },

    payment_system_mobile() {
      let val = "Stripe, $"
      if (!this.hasValidAgreement) {
        if (this.$store.state.current.webuser.location === 'RU') {
          val = "by Stripe/Yoomoney"
        }
      } else {
        val = "by agreement " + this.$store.state.proto.agreement.id + "/" + this.$store.state.proto.agreement.displayId + " with " + this.$store.state.proto.agreement.distributor
      }
      return val
    },
    payment_system() {
      let val = "Stripe, $"
      if (!this.hasValidAgreement) {
        if (this.$store.state.current.webuser.location === 'RU') {
          val = "Yoomoney, ₽ or Stripe, €"
        }
      } else {
        val = "Agreement '" + this.$store.state.proto.agreement.id + "/" + this.$store.state.proto.agreement.displayId + "' with " + this.$store.state.proto.agreement.distributor + " till  " + formatDate(this.$store.state.proto.agreement.validUntil).date

      }
      return val
    },
    is_request() {
      // return this.$store.state.proto.order.order_status === 'request'
      return this.$store.state.proto.order.step === 'creating' || this.$store.state.proto.order.update
    },
    // order_with_date() {
    //   let date = this.$store.state.proto.order.order_date
    //   let order = this.$store.state.proto.order.order_number ? this.$store.state.proto.order.order_number : ""
    //   // return order + "/" + date
    //   return date
    // },

    is_orders_loading() {
      return this.$store.state.current.loading.orders;
    },
    is_primar_loading() {
      return this.$store.state.current.loading.primarInfo;
    },
    is_drawing() {
      return this.$store.state.current.loading.drawing;
    },
    orders() {
      return this.$store.state.charts.s63.orders;
    },
    ordersList() {
      return this.$store.state.charts.s63.orders_list;
    },
    summary() {
      return this.$store.state.charts.s63.summary;
    },
    ecdis_summary() {
      return this.$store.state.charts.s63.ecdis_summary;
    },
    completedOrdersList() {
      let list = this.$store.state.charts.s63.orders_list.filter(function (e) {
        return e.status === 'complete';
      });

      return list;
    },
    errorOrdersList() {
      let list = this.$store.state.charts.s63.orders_list.filter(function (e) {
        return e.status === 'error';
      });

      return list;
    },
    processingOrdersList() {
      let list = this.$store.state.charts.s63.orders_list.filter(function (e) {
        return e.status != 'new' && e.status != 'complete' && e.status != 'error';
      });

      return list;
    },
    unpaidOrdersList() {
      let list = this.$store.state.charts.s63.orders_list.filter(function (e) {
        return e.status === 'new';
      });

      return list;
    },
    periodsList() {
      return this.$store.state.current.cart.currentBandPeriods;
    },
    subTypesList() {
      let pList = this.periodsList;
      let myList = [];
      Object.keys(pList).forEach(x => {
        myList.push({value: x, text: pList[x]})
      })
      console.log(myList, "my list")
      return myList.reverse()
    },


  },
  methods: {
    send_pay() {
      eventBus.$emit('mobile-pay-pressed')
    },

    send_pay_ok() {
      eventBus.$emit('mobile-pay-ok-pressed')
      // this.$refs


      router.push({name: 'transactions'});
    },

    isRUBAgreement() {
      return this.$store.state.proto.agreement.currency === 'RUB';
    },

    isAllCellsPriceReady() {
      return this.$parent.isAllCellsPriceReady();
    },

    disabledCellsCount() {
      return this.$parent.disabledCellsCount();
    },


    importOrder() {
      // this.showPrimarMapBoosterByList()
      this.$parent.openCellsList = true;
    },
    // showPrimarMapBoosterByList() {
    //   alert(2)
    //   this.$parent.openCellsList = true;
    // },
    // primarMapBoosterByList(type) {
    //   this.$parent.openCellsList = false;
    //   let cells = this.cellList.split(/[\n,]/);
    //
    //   cells.forEach((item) => {
    //     this.map.onCellClicked(item.trim());
    //   });
    //   this.map.dispatchSelectedCells();
    //
    //   this.primarOrderCreatingStart()
    // },

    setStatus(status) {
      return this.$parent.setStatus(status);
    },
    setPeriod(period) {
      return this.$parent.setPeriod(period);
    },
    async updateOrder() {
      return this.$parent.updateOrder();
    },
    toggleCellInclusion(id) {
      console.log()
      this.$store.dispatch('current/updateCellStaus', id).then( status => {
        console.log(status)
        if (status){
          this.$parent.map.unselectCell(id);
          this.$parent.countTotalPrice()
        }
        else {
          const m = this.$parent.map.match(id);
          const scope = m.scope;
          const field = m.field;
          this.$parent.map.replaceCell(scope, field, id)
          this.$parent.countTotalPrice()
        }
      })
    },
    checkAndPlaceOrder() {
      this.$parent.checkOrder = true
      // this.$parent.show_check_order_spinner = true
      this.$parent.x = "bbb"

      this.$parent.excludedCells = []
      this.$parent.includedCells = []


      this.$store.dispatch('fickle/getDeviceOrders',
          {
            device_id: this.$store.state.proto.order.device_id,
          }).then(() => {

        this.$store.state.current.cart.s63cells.forEach(item => {
          if (!item.disabled) {
            let allOrderedCells = this.$store.state.fickle.orders_list.cells
            let objIndex = allOrderedCells.findIndex((obj => obj.id === item.id));

            if (objIndex !== -1) {
              if (allOrderedCells[objIndex].days_valid > 28) {
                this.$parent.excludedCells.push({id: item.id, valid: allOrderedCells[objIndex].days_valid})
                this.toggleCellInclusion(item.id)
                // let cart = this.$store.state.current.cart.s63cells
                // let objIndex = cart.findIndex((obj => obj.id === item.id));
                // cart.splice(objIndex, 1)
              }
            }
          }
        })

        let cellsList = this.$store.state.current.cart.s63cells.filter(function (e) {
          return e.disabled === false;
        });

        let excludedCells = this.$parent.excludedCells
        cellsList.forEach(cell => {
          let objIndex = excludedCells.findIndex((obj => obj.id === cell.id))
          if (objIndex === -1) {
            this.$parent.includedCells.push({id: cell.id})
          }
        })
        // this.$parent.includedCells = cellsList.filter(function (e) {
        //   let objIndex = excludedCells.findIndex((obj => obj.id === e.id))
        //   return  objIndex === -1
        // })

        this.$parent.show_check_order_spinner = false
        // this.placeOrder()
      })
    },

    placeOrder() {

      let cellsList = this.$store.state.current.cart.s63cells.filter(function (e) {
        return e.disabled === false;
      });

      // let includedList = this.$parent.includedCells
      // let cellsListNoDuplicates = cellsList.filter(function (e) {
      //   let objIndex = includedList.findIndex((obj => obj.id === e.id))
      //   return objIndex !== -1
      // })

      let cellsListNoDuplicates = cellsList

      let chartsCatalog = this.$store.state.charts.s63.primar_map_info
      let objIndex, band
      this.$parent.cellsBands = [0, 0, 0, 0, 0, 0]
      cellsListNoDuplicates.forEach((item) => {
        objIndex = chartsCatalog.findIndex((obj => obj.id === item.id));
        band = chartsCatalog[objIndex].usageBand
        this.$parent.cellsBands[band - 1] = this.$parent.cellsBands[band - 1] + 1

      });

      store.dispatch('proto/updateOrder',
          {
            cells: cellsListNoDuplicates,
            period: this.selectedPeriod,
            total_price: this.$store.state.current.cart.totalPriceByPrimar,
            total_priceNet: this.$store.state.current.cart.totalPriceByPrimarNet,
            total_priceAdd: this.$store.state.current.cart.totalPriceByPrimarAdd,
            total_priceRUB: this.$store.state.current.cart.totalPriceByPrimarRUB,
            total_priceAgreementRUB: this.$store.state.current.cart.totalPriceByPrimarAgreementRUB,
            total_priceAgreementUSD: this.$store.state.current.cart.totalPriceByPrimarAgreementUSD,
            step: "placed",
            customer: this.$store.state.auth.user.email,
            // customer: this.$store.state.auth.user.email + ' (web)',
            product: 'Primar',
            payment: 'agreement №11/2023-06-23 ',
            bands: this.$parent.cellsBands
          })


      this.map.makeChartsUnselectable();
      this.map.clearRoute();

    },
    sendOrder() {
      return this.$parent.sendOrder();
    },
    goTransactions() {
      this.$store.dispatch('proto/addTransaction', this.$store.state.proto.order).then(() => {
        setTimeout(() => {
          this.cancelOrder()
          router.push({name: 'transactions'});
          router.push({name: 'proxy-orders'});
        }, 1000);
      });
    },

    cancelOrder() {

      console.log("Cancel Order")

      this.selectedPeriod = {period_code: "4", period: "3"}
      this.$parent.cellsBands = [0, 0, 0, 0, 0, 0]
      this.$store.commit('proto/setOrderStatus', 'request')

      store.dispatch('proto/cancelOrder')
      eventBus.$emit('clear-order-text-on-chart')

      this.map.makeChartsUnselectable();
      this.map.clearRoute();
      eventBus.$emit("s63-order-finished1");
      eventBus.$emit("s63-order-finished2");


    },

  },
  beforeDestroy() {

    this.$store.dispatch('proto/keepOrderToPayment'
    ).then(() => {
    })

  }
}
</script>

<style scoped>

</style>