import Vue from 'vue'
import VueRouter from 'vue-router'
import allroutes from './routes'
import store from '../store'
import RootComponent from '@/components/RootComponent.vue'
import {productsByEmail} from "@/utils/utils";
import {eventBus} from "@/main";

Vue.use(VueRouter)

const routes = [{
  path: `/`,
  component: RootComponent,
  children: allroutes
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // alert(3)
  // alert(from.name)
  if (to.name === 'login') {
    // alert(from.name)
    console.log(to)
    console.log(from)
    console.log(next)
  }

  if (to.name == 'login'){
    localStorage.clear()
    store.commit('proto/clearProto')
  }

  if (to.name != "login" && to.name != "viewer" && to.name != null && from.name != null && to.name != "mobile") {
    store.dispatch('auth/refreshUser');
  }
  // if(from.name == "charts"){
  //
  //   eventBus.$emit('charts-tab-exit');
  //
  //   // store.commit("routes/resetMapCenter")
  //   store.commit('setSelectedGeocontent', JSON.parse(localStorage.getItem('selected-geocontent')));
  //   store.commit('addAppState', JSON.parse(localStorage.getItem('appState')));
  //   console.log(store.state.current.profile, store.state.manage.selected_geocontent)
  // }
  if (to.name == "charts"){
    // alert(to.name, from.name)
    console.log(localStorage.getItem('selected-geocontent'),  localStorage.getItem('appState'));
    // TODO CATALOG
    // TODO
    // if (store.state.manage.is_user_has_installation !== false) {
    //   store.commit('setSelectedGeocontent', JSON.parse(localStorage.getItem('selected-geocontent')));
    //   store.commit('addAppState', JSON.parse(localStorage.getItem('appState')));
    // }
    // alert(1)

    if (store.state.proto.ship_selected){
      // alert(2)
      next();
    }
    else {
      next('routes')
      // router.push({name: 'ships'})
      // // TODO:CATALOG next();
      // // alert("no user id")
      // alert(from.name)
    }
  }
  // else if (to.name == "charts"){
  //   next('order')
  //   // TODO:CATALOG next();
  //   // alert("no user id")
  //   // alert(from.name)
  //   // alert(to.name)
  // }

  if (to.meta.guest) next()
  else if (localStorage.getItem('user') == null) {
    next({
      name: 'login'
    })
  } else {
    next()
  }
})


export default router
