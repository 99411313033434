<template>
  <div>
    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Draught (m)">
      <b-form-spinbutton id="sb-inline-draught" size="sm" v-model="routesStore.routeSettings.draught" min="1"
                         step="0.2" max="25" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Height (m)">
      <b-form-spinbutton id="sb-inline-height" size="sm" v-model="routesStore.routeSettings.height" min="1" step="0.2"
                         max="50" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Radius (m)">
      <b-form-spinbutton id="sb-inline-radius" size="sm" v-model="routesStore.routeSettings.radius" min="10" step="10"
                         max="500" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="XTE.L (m)">
      <b-form-spinbutton id="sb-inline-xte-l" size="sm" v-model="routesStore.routeSettings.xtel" min="10" step="10"
                         max="3000" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="XTE.R (m)">
      <b-form-spinbutton id="sb-inline-xte-r" size="sm" v-model="routesStore.routeSettings.xter" min="10" step="10"
                         max="3000" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Speed (knots)">
      <b-form-spinbutton id="sb-inline-speed" size="sm" v-model="routesStore.routeSettings.speed" min="1" step="1"
                         max="50" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Track ratio">
      <b-form-spinbutton id="sb-inline-ratio" size="sm" v-model="routesStore.routeSettings.trackratio" min="0.1"
                         step="0.1" max="0.9" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Shallow water ratio">
      <b-form-spinbutton id="sb-inline-shallowratio" size="sm" v-model="routesStore.routeSettings.shallowratio"
                         min="1" step="0.5" max="10" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Avoid shallow water strength">
      <b-form-spinbutton id="sb-inline-shallowstrength" size="sm"
                         v-model="routesStore.routeSettings.avoidshallowstrength" min="1" step="0.5" max="10"
                         locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Avoid distance">
      <b-form-spinbutton id="sb-inline-avoiddistance" size="sm" v-model="routesStore.routeSettings.avoiddistance"
                         min="0" step="10" max="3000" locale="en"></b-form-spinbutton>
    </b-form-group>

    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Start date">
      <b-form-datepicker id="sb-inline-date" size="sm" v-model="routesStore.routeSettings.startdate"
                         placeholder="Select a date" locale="en"></b-form-datepicker>
    </b-form-group>

    <b-form-group class="mb-0" label-cols="6" label-size="sm" label="Start time">
      <b-form-timepicker id="sb-inline-time" :hour12=false size="sm" v-model="routesStore.routeSettings.starttime"
                         placeholder="Select a time" locale="en"></b-form-timepicker>
    </b-form-group>

    <b-form-group class="mb-0" label-cols="10" label-size="sm" label="Recommended tracks">
      <b-form-checkbox id="sb-inline-use-recommended" size="sm"
                       v-model="routesStore.routeSettings.userecommendedtracks" locale="en"></b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import {eventBus} from "@/main";
import {useRoutesStore} from "@/stores/routes";
import {mapStores} from "pinia";
import {debounce} from 'vue2-debounce'
import dayjs from "dayjs";

export default {
  data() {
    return {
      debounceDepth: {
        isDebounced: false,
      },
    }
  },
  watch: {
    'routesStore.routeSettings.draught'(newVal) {
      if (!this.debounceDepth.isDebounced) {
        this.debounceDepth.isDebounced = true
        debounce(val => this.redrawNoGo(val), 2000)(newVal)
      }
    },
    'routesStore.routeSettings.xtel'() {
      eventBus.$emit('xte-changed')
    },
    'routesStore.routeSettings.xter'() {
      eventBus.$emit('xte-changed')
    },
    'routesStore.routeSettings.startdate'() {
      eventBus.$emit('route-datetime-changed')
    },
    'routesStore.routeSettings.starttime'() {
      eventBus.$emit('route-datetime-changed')
    },
  },
  mounted() {
    let now = dayjs()
    // console.log("nn24", now)
    // console.log("nn24 1", dayjs(now).format("YYYY-MM-DD"))
    // console.log("nn24 2", dayjs(now).format("HH:mm:ss"))
    // console.log("nn24 3", this.routesStore.routeSettings.startdate)
    // console.log("nn24 4", this.routesStore.routeSettings.starttime)
    this.routesStore.addRouteDate(dayjs(now).format("YYYY-MM-DD"))
    this.routesStore.addRouteTime(dayjs(now).format("HH:mm:ss"))
  },
  beforeDestroy() {
  },
  computed: {
    ...mapStores(useRoutesStore)
  },
  methods: {
    redrawNoGo(val) {
      this.debounceDepth.isDebounced = false
      eventBus.$emit('change-nogo');
    }
  }
}
</script>
