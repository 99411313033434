<template>
  <div>
    <b-overlay :show="loadingFlag" rounded="sm" spinner-variant="danger">
      <b-modal ref="routeSaved" title="Route" ok-only>
        <p>The route saved</p>
      </b-modal>

      <b-container fluid>
        <!--        {{routesStore.getRoute}}-->


        <b-overlay opacity="0.3" :show="$store.state.routes.progress.isCalculating" rounded="sm">

          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">Route/Safety calculating, please wait...</p>

              <b-progress class="mt-2" :max=100 show-value show-progress animated>
                <b-progress-bar :value="$store.state.routes.progress.calculatingProgress"
                                :label="`${($store.state.routes.progress.calculatingProgress)}%`"
                                variant="primary"></b-progress-bar>
              </b-progress>

              <b-button class="mt-2" size="sm" @click="cancelRoute()" variant="danger">Cancel</b-button>
            </div>
          </template>


        <b-row class="mt-2">


          <b-col v-show="displayContent === 'showRoute'" order=4 order-lg=1 lg="4">



              <!--            <b-container>-->
              <!--              <b-row>-->
              <div>
                <b-button-group block class="mb-2 btn-block">
                  <b-button :variant="getRouteButtonStatus('ports')" size="sm" @click="showPorts()">Ports</b-button>
                  <b-button :variant="getRouteButtonStatus('routes')" size="sm" @click="showRoutes()">Routes</b-button>
                  <b-button :variant="getRouteButtonStatus('settings')" size="sm" @click="showSettings()">Settings</b-button>
                </b-button-group>
              </div>
              <!--              </b-row>-->
              <!--            </b-container>-->

              <!--            <h6 v-if="!is_mobile" align="center" class="mt-2">ROUTE. WAYPOINTS</h6>-->
              <!--            <div class="mt-1 mb-1">-->

              <!--              <b-dropdown size="sm" variant="info" right text="Waypoints">-->
              <!--                <b-dropdown-item disable @click="">Weather</b-dropdown-item>-->
              <!--              </b-dropdown>-->

              <!--              <b-dropdown class="ml-1" size="sm" variant="info" right text="Files">-->
              <!--                <b-dropdown-item @click="newRoute()">New</b-dropdown-item>-->
              <!--                <b-dropdown-divider></b-dropdown-divider>-->
              <!--                <b-dropdown-item @click="loadRoute()">Load</b-dropdown-item>-->
              <!--                <b-dropdown-item @click="saveRoute()">Save</b-dropdown-item>-->
              <!--                <b-dropdown-item disabled>Edit</b-dropdown-item>-->
              <!--              </b-dropdown>-->

              <!--              <b-button-group>-->
              <!--&lt;!&ndash;                <b-button class="ml-1" size="sm" @click="displayContent = 'showSettings'" variant="info">Settings</b-button>&ndash;&gt;-->
              <!--&lt;!&ndash;                <b-button @click="sendRouteToOrder" v-if="$store.state.routes.route.length > 1" class="ml-1" size="sm" variant="warning">Order ENCs</b-button>&ndash;&gt;-->
              <!--&lt;!&ndash;                <b-button class="ml-1" size="sm" @click="createPDF()" variant="info">Generate PDF</b-button>&ndash;&gt;-->
              <!--              </b-button-group>-->
              <!--            </div>-->

              <b-tabs v-if="$store.state.routes.route.length > 1" v-model="tabIndex" pills small>
                <b-tab title="WayPoints" active @click="returnToRoute()">
                  <!--                <voyage-plan v-if="!is_mobile"></voyage-plan>-->
                  <RouteTable :fields="fields"></RouteTable>
                </b-tab>
                <b-tab title-item-class="d-none" title="111" @click="returnToSettings()">
<!--                  <route-settings></route-settings>-->
                </b-tab>
                <b-tab title="Weather" @click="returnToWeather()">
                  <wearher-settings></wearher-settings>
                </b-tab>
                <b-tab title-item-class="d-none" title="Zones">
<!--                  <zones-settings></zones-settings>-->
                </b-tab>
                <b-tab :title-item-class="getTabStatus()" @click="tabPassagePlanClicked()" title="Passage Plan">
                  <passage-plan ref="passage-plan"></passage-plan>
                </b-tab>
                <b-tab :title-item-class="getTabStatus()" @click="tabOrderClicked()" title="Order">
                  <cells-by-route ref="cells-by-route"></cells-by-route>
                </b-tab>

              </b-tabs>

          </b-col>


          <b-col v-show="displayContent === 'showSearch'" order=4 order-lg=1 lg="4">
            <h6 align="center" class="mt-2">SEARCH</h6>
            <b-button class="border-0 float-right" size="sm" variant="outline-danger"
                      @click="clearPorts();displayContent = 'showRoute'">
              <b-icon icon="x" font-scale="1.5"></b-icon>
            </b-button>
            <br><br>
            <search></search>
          </b-col>

          <b-col v-show="displayContent === 'showSettings'" order=4 order-lg=1 lg="4">
            <h6 align="center" class="mt-2">SETTINGS</h6>
            <b-button class="border-0 float-right" size="sm" variant="outline-danger"
                      @click="displayContent = 'showRoute'">
              <b-icon icon="x" font-scale="1.5"></b-icon>
            </b-button>
            <br><br>
            <route-settings></route-settings>
          </b-col>

          <!--          <b-col v-show="displayContent === 'showSettings'" order=4 order-lg=1 lg="4">-->
          <!--            <h6 align="center" class="mt-2">SETTINGS</h6>-->
          <!--            <b-button class="border-0 float-right" size="sm" variant="outline-danger"-->
          <!--                      @click="displayContent = 'showRoute'">-->
          <!--              <b-icon icon="x" font-scale="1.5"></b-icon>-->
          <!--            </b-button>-->
          <!--            <br><br>-->
          <!--            <RouteSettings></RouteSettings>-->
          <!--          </b-col>-->

          <b-col v-if="displayContent === 'showInfo'" order=4 order-lg=1 lg="4">
            <h6 align="center" class="mt-2">MAP INFO</h6>
            <b-button class="border-0 float-right" size="sm" variant="outline-danger" @click="closeMapInfo()">
              <b-icon icon="x" font-scale="1.5"></b-icon>
            </b-button>

            <div v-if="!$store.state.fickle.routes.objectsInfoLoading">
              <b-button v-if="map_info_text.length > 0" class="border-0 float-right" size="sm"
                        variant="outline-secondary" @click="closeMapInfoItem()">
                <b-icon icon="arrow-left-short" font-scale="1.5"></b-icon>
              </b-button>
              <div v-for="(item, i) in $store.state.fickle.routes.objectsInfo.info">
                <span class='listsmall'><a href='#'
                                           @click='showObject(item.id, item.name, item.locationType, i+1, true)'>{{
                    i + 1
                  }}: {{
                    item.name
                  }} </a><br></span>
                <!--                <span v-if="map_info_object !== item.id" class='listsmall'><a href='#' @click='showObject(item.id, item.name, item.locationType, i+1)'>{{ i+1 }}: {{ item.name }} </a><br></span>-->
                <!--                <span v-if="map_info_object.length > 0 && map_info_object === item.id" class='listsmall'>{{ i+1 }}: {{ item.name }} <br></span>-->
              </div>
              <hr>
              <div v-html="map_info_text"/>
            </div>
            <div class="text-center text-danger my-2" v-else>
              <b-spinner small class="align-middle"></b-spinner>
              <strong>&nbsp;Loading...</strong>
            </div>


            <!--            <div v-for="(item) in $store.state.fickle.routes.objectsInfo.info">-->
            <!--              <details><summary>{{ item.name }}</summary>-->
            <!--                <ul>-->
            <!--                <div v-for="(attribute) in item.attributes">-->
            <!--                  <li><span class='listsmall'>{{ attribute.name }}: </span>-->
            <!--                  <span class='small-black'>{{ attribute.value }}</span></li>-->
            <!--                </div>-->
            <!--                  <li v-if="item.lines.length > 0"><span class='listsmall'><a href='#' @click='showObject(item.name)'>Show on map</a></span></li>-->
            <!--                </ul>-->
            <!--              </details>-->
            <!--            </div>-->
            <!--            <div v-html="map_info_text"/>-->
          </b-col>

    <b-col order=1 order-lg=2 lg="8">
      <my-map :map=map :height=map_size></my-map>
    </b-col>

    </b-row>

        </b-overlay>
    </b-container>
    </b-overlay>

  </div>
</template>

<script>
import RoutesMap from "@/views/routes/RoutesMap";
import RouteSettings from "@/views/routes/RouteSettings.vue";
// import VoyagePlan from "@/views/routes/VoyagePlan.vue";
import {RouteLeaf} from "@/my-leaf/routes/route-leaf";
import RouteTable from "@/views/routes/RouteTable.vue";
import WearherSettings from "@/views/routes/WearherSettings.vue";
import ZonesSettings from "@/views/routes/ZonesSettings.vue";
import Search from "@/views/routes/Search.vue";
import {eventBus} from "@/main";
import {routes_map} from "@/main";
import router from "@/router";
import {checkInternetLoop} from "@/helpers/offline";
import store from "@/store";
import {myAxios, myAxiosRetry} from "@/services/myAxios";
import {useMomentaryStore} from "@/stores/momentary";
import L from "leaflet";
// import type { Template } from '@pdfme/common';
// import { generate } from '@pdfme/generator';

// import {createVoyagePlan} from "@/views/routes/components/VoyagePlan";

import {useRoutesStore} from "@/stores/routes";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// import {ROUTES_STORE} from "@/stores";
// import {useRoutesStore} from "@/stores/routes";
import {mapStores} from "pinia";
import {createVoyagePlan} from "@/views/routes/components/VoyagePlan";
import PassagePlan from "@/views/routes/PassagePlan.vue";
import CellsByRoute from "@/views/routes/CellsByRoute.vue";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: {RouteTable, MyMap: RoutesMap, RouteSettings, Search, WearherSettings, ZonesSettings, PassagePlan, CellsByRoute},
  data() {
    return {
      // STORE_ROUTES: useRoutesStore(),

      interval: null,

      preparingPassagePlan: true,

      displayContent: 'showRoute',
      map_info_text: '',
      map_info_object: '',

      loadingFlag: false,
      // spinner: false,
      windowWidth: window.innerWidth,
      map: routes_map,
      mapHeight: window.innerHeight / 1.2,
      mapHeightMobile: window.innerHeight / 1.8,

      tabIndex: 0,
      aaa: 0,

      fields: [
        {key: 'name', label: 'name', type: 'string', tdClass: 'small-black', thClass: 'small-gray',},
        {key: 'lat', label: 'Lat', type: 'number', tdClass: 'small-black', thClass: 'small-gray',},
        {key: 'lon', label: 'Lon', type: 'number', tdClass: 'small-black', thClass: 'small-gray',},
      ],
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }

    this.interval = setInterval(checkInternetLoop, 5000)

    // if (this.$route.query?.rid !== '' && this.$route.query?.rid !== undefined) {
    //   myAxios.get(`http://route-builder.m-s-solutions.net/routes/ca5ff137-54cf-4948-a1eb-1a9201c82cd9`)
    //       .then(resp => {
    //         store.commit("fickle/setGlobalRoute", resp.data)
    //       });
    //
    //
    // }


    if (this.$store.state.routes.route.length > 0) {
      eventBus.$emit('restore-route')
    } else {
      eventBus.$emit('load-route-data')
    }

    eventBus.$off('search-on-map')
    eventBus.$off('send-order-route')
    eventBus.$off('show-map-info')
    eventBus.$off('change-tab-zones')
    eventBus.$off('show-waypoints')

    eventBus.$on('change-tab-zones', () => {
      this.tabIndex = 3
    })

    eventBus.$on('search-on-map', () => {
      this.displayContent = 'showSearch'
    })

    eventBus.$on('show-waypoints', () => {
      this.displayContent = 'showRoute'
    })

    eventBus.$on('send-order-route', () => {
      router.push({name: 'charts'}).catch(() => {
      });
    })

    eventBus.$on('show-map-info', (payload) => {
      this.map_info_text = ""
      this.map_info_object = ""
      // eventBus.$emit('clear-object')
      routes_map.aaaa()
      store.commit("fickle/setObjectInfo", payload)


      // this.map_info_text = ''
      // payload.info.forEach(item => {
      //   this.map_info_text += "<details><summary>" + item.name + "</summary><ul>"
      //   item.attributes.forEach(attribute => {
      //     this.map_info_text += "<li><span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span></li>"
      //   })
      //   if (item.lines.length > 0) {
      //     this.map_info_text += "<li><span class='listsmall'><a href='#' :click=showObject()>Show on map</a></span></li>"
      //   }
      //   this.map_info_text += "</ul></details>"
      // })
      this.displayContent = 'showInfo'
    })

  },
  watch: {
    // 'tabIndex'(newVal, oldVal) {
    //   if (newVal === 0 || newVal === 1)
    //     eventBus.$emit('hide-zones')
    //   if (newVal === 2)
    //     eventBus.$emit('show-zones')
    // },
  },
  computed: {
    ...mapStores(useRoutesStore, useMomentaryStore),

    // routes_store() {
    //   return this.STORE_ROUTES
    // },
    is_mobile() {
      return this.windowWidth <= 760
    },
    map_size() {
      if (this.is_mobile)
        return window.innerHeight / 1.8
      else
        return window.innerHeight / 1.2
    },
  },
  methods: {
    // createPDF() {
    //   // const routesStore = useRoutesStore()
    //   createVoyagePlan()
    // },
    newRoute() {
      this.map.removeRoute()
    },

    getTabStatus() {
      if (this.$store.state.routes.route.length > 1)
        return ''
      else
        return 'd-none'
    },
    tabOrderClicked() {
      // let points = []

      this.$refs["cells-by-route"].prepareCells()

      // this.$parent.$parent.$parent.$parent.loadingFlag = true
      // this.$parent.$parent.loadingFlag = true

      // this.routesStore.route.forEach(item => {
      //   points.push({lat: item.lat, lon: item.lon})
      // })
      //
      // return new Promise(resolve => {
      //   myAxiosRetry.post("api/v1/mss/maps", points
      //   ).then(resp => {
      //     this.momentaryStore.addChartsByRoute(resp.data)
      //     router.push({name: "proxy-orders"});
      //     resolve()
      //   });
      // })
    },
    tabPassagePlanClicked() {
      this.$refs["passage-plan"].createPDF()
    },
    showPorts() {
      this.displayContent = 'showSearch'
    },

    showRoutes() {
      this.displayContent = 'showRoute'
    },
    showSettings() {
      this.displayContent = 'showSettings'
    },

    // showOrders() {
    //   router.push({name: "proxy-orders"});
    // },

    getRouteButtonStatus(type) {
      if (this.displayContent === 'showRoute' && type === 'routes')
        return 'primary'
      else
        return 'outline-primary'
    },


    returnToRoute() {
      eventBus.$emit('cancel-weather')
    },
    returnToWeather() {
      eventBus.$emit('show-weather')
      eventBus.$emit('init-weather')
    },
    closeMapInfo() {
      this.displayContent = 'showRoute'
      eventBus.$emit('clear-object')
    },
    closeMapInfoItem() {
      this.map_info_text = ''
      this.map_info_object = ''
      eventBus.$emit('clear-object')
    },
    showObject(id, name, type, i, needZoom) {

      this.map_info_text = "<span class='small-cyan'>" + i + ": " + name + " (" + type + ")</span><hr>"
      this.map_info_object = id

      // this.map_info_text = ''
      this.$store.state.fickle.routes.objectsInfo.info.forEach((item, index) => {
        if (item.name === name && i - 1 === index) {

          this.map_info_text += ""
          item.attributes.forEach((attribute, i) => {
            this.map_info_text += "<span class='listsmall'>" + attribute.name + ":</span> <span class='small-black'>" + attribute.value + "</span><br>"
          })
          this.map_info_text += "<hr>"
        }
      })


      // let x = this.$store.state.fickle.routes.objectsInfo.info;
      let points = this.$store.state.fickle.routes.objectsInfo.info.filter(function (e) {
        // console.log(x.findIndex(name))

        return e["id"] === id;
      });

      let lines = points[0].lines

      // if (name === "Depth area") {
      //   lines.pop()
      // }

      console.log("mmmm2", name, id)
      console.log("mmmm2", lines)


      eventBus.$emit("draw-object", {lines: lines, type: type, zoom: needZoom})


    },
    saveRoute() {
      eventBus.$emit('keep-route-settings')
      localStorage.setItem('route', JSON.stringify(this.$store.state.routes.route));
      localStorage.setItem('route-settings', JSON.stringify(this.$store.state.routes.route_settings));
      this.$refs['routeSaved'].show()
    },
    loadRoute() {
      let route = JSON.parse(localStorage.getItem('route'))
      let settings = JSON.parse(localStorage.getItem('route-settings'))
      this.$store.commit('routes/setRoutePoints', route)
      this.$store.commit('routes/setRouteSettings', settings)
      eventBus.$emit('restore-route')
    },
    clearPorts() {
      this.map.clearPorts();
    },
    async onLoadingFlag() {
      this.loadingFlag = true;
    },
    // async sendRouteToOrder() {
    //   await this.onLoadingFlag();
    //   setTimeout(() => {
    //     this.$store.commit("routes/useRouteForOrder", true)
    //     eventBus.$emit('send-order-route')
    //   }, 600)
    //
    // },
  },
}
</script>

<!--<script>-->
<!--// eslint-disable-next-line no-unexpected-multiline-->
<!--(function(d,t) {-->
<!--var BASE_URL="https://app.chatwoot.com";-->
<!--var g=d.createElement(t),s=d.getElementsByTagName(t)[0];-->
<!--g.src=BASE_URL+"/packs/js/sdk.js";-->
<!--g.defer = true;-->
<!--g.async = true;-->
<!--s.parentNode.insertBefore(g,s);-->
<!--g.onload=function(){-->
<!--window.chatwootSDK.run({-->
<!--websiteToken: 'nrnUHZ5ZQ66jotURNVUDNuet',-->
<!--baseUrl: BASE_URL-->
<!--})-->
<!--}-->
<!--})(document,"script");-->
<!--</script>-->