<template>
  <div>
    <div class="mt-1"></div>
    <!--    <search></search>-->
    <payment ref="payment"></payment>
    <b-modal v-model="updateWarning" title="Warning" ok-only>
      <p>To edit an order with 'to pay' status it should be changed to 'request'</p>
    </b-modal>

    <b-modal v-model="periodWarning" title="Warning" ok-only>
      <p>The wrong update period is set for some cells in the order. Change the period or split the order into several
        orders.</p>
      <details>
        <summary>Cell with wrong period:</summary>
        <div v-for="(item) in periodWarningList">
          {{ item }}
        </div>
      </details>
    </b-modal>

    <b-modal v-model="checkOrder" no-close-on-backdrop no-close-on-esc hide-header-close title="Checking order" ok-only
             @ok="afterChecking()" :ok-disabled="show_check_order_spinner">
      <div v-if="show_check_order_spinner" class="text-sm-centercenter text-danger my-2" align="center">
        <b-spinner small class="align-middle"></b-spinner>
        <strong>&nbsp;Checking duplicated cells...</strong>
      </div>
      <div v-else>
        <div v-if="notFoundCells.length > 0">
          <span class="smt14">The cells not found in PRIMAR catalog:</span><br>
          <b-table id="orders-for-devices-table1" striped sort-by="id" :items="notFoundCells">
          </b-table>
        </div>

        <div v-if="excludedCells.length > 0">
          <span class="smt14">The duplicated cells will be excluded from the order:</span><br>
          <b-table id="orders-for-devices-table1" striped sort-by="id" :items="excludedCells">
          </b-table>
        </div>
        <div v-else>
          <span class="smt4">No duplicated cells found</span><br>
        </div>
        <!--        <div v-if="includedCells.length > 0">-->
        <!--          The cells will be ordered:<br>-->
        <!--          <b-table id="orders-for-devices-table2" striped sort-by="id" :items="includedCells">-->
        <!--          </b-table>-->
        <!--        </div>-->
        <!--        <div v-else>-->
        <!--          No cells will be ordered<br>-->
        <!--        </div>-->
      </div>
    </b-modal>


    <b-modal id="modal-primar-error" title="Error" v-model="network.isError" ok-only>
      <p class="my-4">Error {{ network.code }}: chart purchasing in temporary unavailable. Try reload the page
        later. </p>
    </b-modal>

    <b-modal title="List of cells" v-model="openCellsList" ok-only @ok="primarMapBoosterByList">
      <b-form-textarea v-model="cellList"
                       placeholder="Paste comma or new line separated cells names..."></b-form-textarea>
    </b-modal>

    <b-modal title="ADP Catalog" v-model="catalogADP" ok-only @ok="">
      <b-table>

      </b-table>
    </b-modal>

    <b-modal title="eNP Catalog" v-model="catalogENP" ok-only @ok="">
      <b-table>

      </b-table>
    </b-modal>

    <b-modal v-if="!IS_NP() && !IS_GETENC()" id="modal-charts" title="Hint" v-model="showHint" ok-only @ok="okHandler">
      <p class="my-4">To purchase Primar maps press <b>New order</b> and select any preferable method (e.g. manual cell
        selection).</p>
      <img src="https://mkartweb.com/pics/hint_02.png" width="200">
      <p class="my-4">For any questions please write us to <a href="mailto:support@mkart.fi">support@mkart.fi</a></p>
    </b-modal>

    <div v-if="show_spinner" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong>&nbsp;Making order...</strong>
      <!--      <strong>&nbsp;{{$store.state.fickle.requests.current_request}}</strong>-->
    </div>

    <b-container fluid>
      <!--      <b-badge>UKHO</b-badge>-->
      <!--      <b-row>-->
      <!--        <b-col>-->
      <!--          <b-badge> show>-->
      <!--            UKHO-->
      <!--          </b-badge>-->
      <!--        </b-col>-->
      <!--      </b-row>-->

      <b-row>
        <b-col order=1 order-lg=2 lg="3">
          <b></b>
          <div v-if="$store.state.proto.order.step !== 'placed'">
            <cells-table v-if="!is_mobile" style="overflow-y: scroll; height: 80vh">
              <!--                         :current-cart="currentCart" :is_mobile="is_mobile" :map=map>-->
            </cells-table>
            <order v-else-if="is_mobile"
                   :selected-period="selectedPeriod"
                   :fee="fee" :vat="vat" :net="net"
                   :map=map
                   :purchase="{}"
                   ref=mycart
                   :is_mobile="is_mobile"
                   :current-cart="currentCart"
                   :has-valid-agreement="hasValidAgreement"></order>
          </div>
          <pre-purchase-summary v-else
                                :is_mobile="is_mobile"
                                :net="net"
                                :vat="vat"
                                :fee="fee"
                                :payment_system="payment_system"
                                :has_valid_agreement="hasValidAgreement"
                                :is-rub-agreement="isRUBAgreement"

          ></pre-purchase-summary>
        </b-col>
        <b-col order=3 order-lg=1 lg="3">
          <b></b>
          <order v-if="!is_mobile"
                 :selected-period="selectedPeriod"
                 :fee="fee" :vat="vat" :net="net"
                 :map=map
                 :purchase="{}"
                 ref=mycart
                 :is_mobile="is_mobile"
                 :current-cart="currentCart"
                 :has-valid-agreement="hasValidAgreement"
          ></order>
          <cells-table style="overflow-y: scroll; height: 80vh" v-else-if="is_mobile" :current-cart="currentCart"
                       :is_mobile="is_mobile"></cells-table>
        </b-col>
        <b-col order=2 order-lg=3 lg="6">
          <b></b>
          <div v-show="$store.state.proto.order.step !== 'placed' || !is_mobile">
            <my-map :map=map :show-route=haveRoute :height="mapHeight"></my-map>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import Payment from "@/views/order-ukho/Payment.vue";
import ChartsMap from "@/views/order-ukho/ChartsMap.vue";
import * as utils from "@/utils/utils";
import {ChartsLeafUKHO} from "../../my-leaf/charts-leaf-ukho";
import {file} from "paths.macro";
import {eventBus} from "@/main";
import {IS_GETENC, IS_NP} from "@/utils/build-config";
import {daysOldWithSign, formatDate, getAppName} from "@/utils/utils";
import router from "@/router";
import Order from "@/views/order-ukho/Order.vue"
import CellsTable from "@/views/order-ukho/CellsTable.vue";
import PrePurchaseSummary from "@/views/order-ukho/PrePurchaseSummary.vue";
import Search from "@/views/routes/Search.vue";
import Details from "@/views/ecdis/Details.vue";
import {checkInternetLoop, loadData, loadENCData, loadUserData} from "@/helpers/offline";
import store from "@/store";
import localforage from "localforage";
import {useMomentaryStore} from "@/stores/momentary";
import {mapStores} from "pinia";

export default {
  components: {Details, Payment, MyMap: ChartsMap, Order, CellsTable, PrePurchaseSummary, Search},
  data() {
    return {
      interval: null,
      show_spinner: false,
      show_table: false,
      periodWarning: false,
      periodWarningList: [],
      updateWarning: false,
      windowWidth: window.innerWidth,
      priceCalculating: false,
      openCellsList: false,
      catalogADP: false,
      catalogENP: false,
      selectedPeriod: {period_code: "4", period: "3"},

      cellList: "",
      selectedProduct: 'Primar',
      options: [
        {item: 'Primar', name: 'Primar charts'},
        {item: 'Navicom', name: 'Navicom charts'},
      ],

      cellsBands: [0, 0, 0, 0, 0, 0],

      show_add_ship_tab: "d-none",
      selected_tab: 1,

      agreement: null,

      selected_ecdis: null,
      showHint: false,
      checkOrder: false,
      show_check_order_spinner: true,

      excludedCells: [],
      includedCells: [],
      notFoundCells: [],

      network: {
        isError: false,
        code: '',
      },

      map: new ChartsLeafUKHO(true),
      haveRoute: false,
      log: "",
      chart_types: [
        {title: "Products"},
        {title: "S63/Primar"},
      ],
      licensing: {},
      selected: 0,
      bagSelected: false
    }
  },
  computed: {
    mapHeight() {
      if (this.is_mobile)
        return window.innerHeight / 1.8
      else
        return window.innerHeight / 1.2
    },

    ...mapStores(useMomentaryStore),

    payment_system() {
      let val = "Stripe, $"
      if (!this.hasValidAgreement) {
        if (this.$store.state.current.webuser.location === 'RU') {
          val = "Yoomoney, ₽ or Stripe, €"
        }
      } else {
        val = "Agreement '" + this.$store.state.proto.agreement.id + "/" + this.$store.state.proto.agreement.displayId + "' with " + this.$store.state.proto.agreement.distributor + " till  " + formatDate(this.$store.state.proto.agreement.validUntil).date

      }
      return val
    },

    isRUBAgreement() {
      return this.$store.state.proto.agreement.currency === 'RUB';
    },

    is_mobile() {
      return this.windowWidth < 992
    },

    hasValidAgreement() {
      return this.$store.state.proto.agreement.exist && daysOldWithSign(this.$store.state.proto.agreement.validUntil) > 0;
    },

    currentCart() {
      return this.$store.state.current.cart.s63cells
    },
    currentCartLen() {
      return this.$store.state.current.cart.s63cells.length
    },


    is_orders_loading() {
      return this.$store.state.current.loading.orders;
    },

    is_primar_loading() {
      return this.$store.state.current.loading.primarInfo;
    },

    is_drawing() {
      return this.$store.state.current.loading.drawing;
    },

    geocontent_list() {
      utils.dumpFile("selected_geocontent", this.$store.state.manage.selected_geocontent)
      return this.$store.state.manage.selected_geocontent;
    },

    products_list() {
      let items = [];

      utils.dumpFile("selected_geocontent", this.$store.state.manage.selected_geocontent)

      if (this.$store.state.manage.selected_geocontent) {
        items = this.$store.state.manage.selected_geocontent.filter(function (e) {
          return e["title"] !== "PRIMAR Data (S63)" && e["title"] !== "User's Data";
        });
      }
      return items;
    },

    s63_list() {
      let items = [];

      if (this.$store.state.manage.selected_geocontent) {
        items = this.$store.state.manage.selected_geocontent.filter(function (e) {
          return e["title"] === "PRIMAR Data (S63)";
        });
      }
      return items;
    },

    mydata_list() {
      let items = [];

      if (this.$store.state.manage.selected_geocontent) {
        items = this.$store.state.manage.selected_geocontent.filter(function (e) {
          return e["title"] === "User's Data";
        });
      }
      return items;
    },

    charts() {
      return this.$store.state.charts.s63.items;
    },

    hasData() {
      return this.$store.state.current.profile.device_id.length > 0 &&
          this.$store.state.current.profile.email.length > 0 &&
          this.$store.state.manage.selected_geocontent;
    },
    vat() {
      return Number((this.$store.state.current.cart.totalPriceByPrimarNet) / 100 * 24).toFixed(2)
    },
    net() {
      // return Number(this.$store.state.current.cart.totalPriceByPrimar -  this.$store.state.current.cart.totalPriceByPrimarAdd).toFixed(2)
      return Number(this.$store.state.current.cart.totalPriceByPrimarNet).toFixed(2)
    },
    fee() {
      let net = Number(this.$store.state.current.cart.totalPriceByPrimarNet)
      let vat = net / 100 * 24
      return Number((net + vat) / 100 * 7).toFixed(2)
    },
  },
  methods: {

    afterChecking() {
      this.notFoundCells = []
      if (this.excludedCells.length === 0)
        eventBus.$emit('place-order')
    },
    order_with_date() {
      let date = this.$store.state.proto.order.order_date
      let order = this.$store.state.proto.order.order_number ? this.$store.state.proto.order.order_number : ""
      // return order + "/" + date
      return date
    },

    // showPrimarMapBoosterByList() {
    //   // alert(4)
    //   this.openCellsList = true;
    // },

    primarMapBoosterByList(type) {
      this.openCellsList = false;
      let cells = this.cellList.split(/[\n,]/);

      cells.forEach((item) => {
        this.map.onCellClicked(item.trim());
      });
      this.map.dispatchSelectedCells();

      // this.primarOrderCreatingStart()
    },

    backToEdit() {
      eventBus.$emit("s63-order-finished");
      this.$store.dispatch('proto/updateOrder', {step: "creating"})

      this.purchase.isPrimarOrderCreating = true;
      this.map.makeChartsSelectable();
      this.map.selectChartsByRoute();

      this.$store.state.proto.order.cells.forEach((item) => {
        this.map.onCellClicked(item.id);
      });
      this.map.dispatchSelectedCells();
      // this.primarOrderCreatingStart()

    },
    sendOrder() {

      // alert(1)

      if (this.$store.state.proto.order.update) {
        console.log("Order updated")
      } else {

        localStorage.removeItem('route')
        localStorage.removeItem('proto')
        localStorage.removeItem('cart')

        localforage.removeItem('route')
        localforage.removeItem('proto')
        localforage.removeItem('cart')


        if (this.$store.state.proto.order.order_status === 'to pay' || this.payment_system !== 'Agreement') {

          if (this.hasValidAgreement) {
            this.$refs.payment.payAgreement()
          } else {
            this.$refs.payment.showModal()
          }
        }
      }
    },
    checkPeriod() {
      let cart = this.$store.state.current.cart.s63cells
      let currentPeriod = Number(this.selectedPeriod.period)
      this.periodWarningList = []
      cart.forEach(item => {
        let allowedPeriods = item.periods
        if (!allowedPeriods.includes(currentPeriod)) {
          this.periodWarning = true
          this.periodWarningList.push(item.id + ": " + allowedPeriods + " months")
        }
      })
    },
    countTotalPrice() {
      if (this.currentCart.length === 0)
        return
      console.log(this.currentCart, 'cc')

      this.priceCalculating = true;

      // hide price while change period
      this.$store.commit("current/setTotalPriceByPrimar",
          {usd: '?', rub: '?', rubineuro: '?'}
      );

      let agreementID = this.hasValidAgreement ? this.$store.state.proto.agreement.id : "-1"
      let deviceID = this.$store.state.proto.order.device_id
      let appType = this.$store.state.proto.order.application_id

      // let quotatesTotal = [this.$store.dispatch("current/getTotalPrice", {products: this.currentCart, periodBand: this.selectedPeriod.period_code, agreementID: agreementID, deviceID: deviceID, appType: appType})]
      this.$store.dispatch("current/getTotalPrice", {
        products: this.currentCart,
        periodBand: this.selectedPeriod.period_code,
        agreementID: agreementID,
        deviceID: deviceID,
        appType: appType
      }).then(() => {
        this.priceCalculating = false;

        // let quotates = []
        // if (this.currentCart.length > 0) {
        //   let items = [];
        //   this.currentCart.forEach(x => {
        //     if (!x.disabled)
        //       quotates.push(this.$store.dispatch("current/getCellPrice", {cell: x, periodBand: this.selectedPeriod.period_code, agreementID: agreementID, deviceID: deviceID, appType: appType}))
        //   })
        // }
      })
      console.log("d++", this.currentCart)

      /////----------------------------

      // let quotates = []
      // if (this.currentCart.length > 0) {
      //   let items = [];
      //   this.currentCart.forEach(x => {
      //     if (!x.disabled)
      //       quotates.push(this.$store.dispatch("current/getCellPrice", {cell: x, periodBand: this.selectedPeriod.period_code, agreementID: agreementID, deviceID: deviceID, appType: appType}))
      //   })
      // }

      /////----------------------------

      // console.log(quotates)
      // this.priceCalculating = true;
      // Promise.allSettled(quotatesTotal).then(() => {
      //   this.priceCalculating = false;
      //
      // })
      console.log({
        products: this.currentCart,
        periodBand: this.selectedPeriod.period_code,
        agreementID: agreementID,
        deviceID: deviceID,
        appType: appType
      })
    },

    visible_rows() {
      let data = this.$store.state.charts.s63.primar_map_info
      return data
    },

    visible_fields() {
      return [
        {key: 'id', label: 'ID', tdClass: 'small-black', thClass: 'small-gray'},
      ]
    },

    setStatus(status) {
      this.$store.commit('proto/setOrderStatus', status)
    },

    isAllCellsPriceReady() {
      let cart = this.currentCart.slice();
      let val = true
      if (cart.length === 0 || this.$store.state.current.cart.totalPriceByPrimar === '') {
        val = false
      }

      if (this.$store.state.current.cart.totalPriceByPrimar === '?') {
        val = false
      }

      return val
    },

    getCellPrice(id) {

      let cellPrice = '?'

      let cellArray = this.$store.state.current.cart.cellPrices
      let objIndex = cellArray.findIndex((obj => obj.id === id));
      if (objIndex !== -1) {

        if (this.hasValidAgreement && this.isRUBAgreement)
          cellPrice = cellArray[objIndex].priceAgreementRub + ' ₽'
        if (this.hasValidAgreement && !this.isRUBAgreement)
          cellPrice = '$' + cellArray[objIndex].priceAgreementUsd
        if (!this.hasValidAgreement)
          cellPrice = cellArray[objIndex].priceEuro + ' €'
      }

      return cellPrice
    },

    async updateOrder() {
      this.show_spinner = true
      if (this.$store.state.charts.s63.latest_order.status === 'pre_order') {
        this.updateItems().then(() => {
          this.updateOrderStatus().then(() => {
            this.show_spinner = false
            router.push({name: 'transactions'});
          })
        })
      } else {
        Promise.allSettled([this.updateOrderStatus()]).then((values) => {
          this.show_spinner = false
          router.push({name: 'transactions'});
          console.log(values)
        })
      }
    },

    async updateItems() {
      return new Promise(resolve => {
        let payload = []
        const cells = this.$store.state.current.cart.cellPrices

        // const price = this.isRUBAgreement ? this.$store.state.current.cart.totalPriceByPrimarAgreementRUB.toString() : this.$store.state.current.cart.totalPriceByPrimarAgreementUSD.toString();
        this.$store.state.current.cart.s63cells.forEach(cell => {
          if (!cell.disabled) {

            let objIndex = cells.findIndex((obj => obj.id === cell.id));
            if (objIndex === -1)
              alert("error")

            let price = cells[objIndex].priceAgreementUsd
            if (this.isRUBAgreement)
              price = cells[objIndex].priceAgreementRub

            payload.push(
                {
                  "id": cell.id,
                  "type": "s63 maps",
                  "monthCount": Number(this.selectedPeriod.period),
                  // "price": "",
                  "price": price,
                  "primarOrderTypeId": Number(this.selectedPeriod.period_code)
                }
            )
          }
        })
        this.$store.dispatch('proto/updateItems', payload).then((resp) => {
          resolve(resp)
        })
      })
    },

    async updateOrderStatus() {
      return new Promise(resolve => {
        this.$store.dispatch('proto/updateOrderStatus',
            {
              order_id: this.$store.state.proto.order.order_number,
              status: utils.getTypeByStatus(this.$store.state.proto.order.order_status)
            }).then(() => {
          resolve(1)
        });
      })

    },

    send_cancel() {
      eventBus.$emit('mobile-cancel-pressed')
    },

    setPeriod(period) {
      if (period === 12)
        this.selectedPeriod = {period_code: "0", period: "12"}
      if (period === 6)
        this.selectedPeriod = {period_code: "1", period: "6"}
      if (period === 3)
        this.selectedPeriod = {period_code: "4", period: "3"}

      this.checkPeriod()

      this.countTotalPrice()
    },

    disabledCellsCount() {
      let count = 0;
      this.currentCart.slice().forEach(item => {
        if (item.disabled) {
          count++
        }
      })
      return count;
    },

    isECDISSelected() {
      return (this.$refs['ecdis-list'] && this.$refs['ecdis-list'].ship && this.$refs['ecdis-list'].ship.device_id)
    },

    isDeviceSelected() {
      return (this.$refs['devices-list'] && this.$refs['devices-list'].ship && this.$refs['devices-list'].ship.device_id)
    },

    IS_GETENC() {
      return IS_GETENC
    },

    init_map() {
      console.log("hhh 1")

      console.log("c-- init map", new Date().getMinutes(), new Date().getSeconds())
      console.log("init map")
      eventBus.$emit('restore-primar-tiles')
      eventBus.$emit('add-primar-controls')


      if (this.$store.state.charts.s63.products.all.length > 0) {
        // setInterval(() => {this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info)}, 3000)
        this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info);
        this.$store.dispatch('current/drawing', false);

        this.selectCellByRoute()

      } else {
        this.$store.dispatch('getENCs').then(() => {
          console.log("c-- draw", new Date().getMinutes(), new Date().getSeconds())
          console.log('init draw all cells from', file)
          // setInterval(() => {this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info)}, 3000)
          this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info);
          this.$store.dispatch('current/drawing', false);

          this.selectCellByRoute()
        });
      }

      console.log("hhh 2")

    },

    selectCellByRoute() {
      console.log("hhh 3")

      if (this.momentaryStore.route.chartsbyroute.length > 0) {
        setTimeout(() => {

          this.momentaryStore.route.chartsbyroute.forEach((item) => {
            this.map.onCellClicked(item.trim());
          });
          this.map.dispatchSelectedCells();
          this.momentaryStore.route.clearChartsByRoute()

        }, 0);
      }

    },

    showNewShipTab() {
      this.show_add_ship_tab = ''
      this.selected_tab = 2
    },

    shipSelected(type) {
      this.$refs[type].onDone("order-primar")

      eventBus.$emit('set-new-ship-text-on-chart', this.$store.state.proto.order.ecdis_name)
      eventBus.$emit('set-new-order-text-on-chart', {
        order: this.$store.state.proto.order.order_number,
        order_date: this.$store.state.proto.order.order_date
      })
      eventBus.$emit('ship-selected-from-chart')
      this.$store.dispatch('proto/updateOrder', {step: "creating"})
    },

    IS_NP() {
      return IS_NP
    },

    productsByProduct(product) {
      let str = [];

      if (product) {
        product.forEach((prod) => {
          // str += prod.type + ", ";
          str.push(prod.type);
        });
      }
      return str;
    },

    formatDate(date) {
      return utils.formatDate(date).date;
    },

    onOrderCreation(payload) {
      let keys = [];

      this.charts.forEach(item => {
        console.log(item)
        keys.push({id: item.name, monthCount: "" + item.period, price: "" + item.price});
      })
      this.$store.dispatch('addS63Order', {keys: keys, price: payload.price, uuid: payload.uuid});
      this.$store.dispatch('getENCs').then(() => {
        this.$store.dispatch("getS63Summary", {
          device_id: this.$store.state.current.profile.device_id,
          user_id: this.$store.state.current.profile.user_id,
        }).then(() => {
          console.log('init draw all cells from', file)

          this.map.drawAllCells(this.$store.state.charts.s63.primar_map_info);
        })
      })
    },

    allProducts() {
      let all_prods = this.$store.state.manage.products;
      let prods = this.$store.state.charts.profile.products;

      let dataArray = [];

      prods.forEach((inst) => {
        // console.log(inst.productInfo.productId)
        dataArray.push(inst.productInfo.productId);

        all_prods = all_prods.filter(function (e) {
          return e["productId"] != inst.productInfo.productId && e["licenseType"] != "corp";
        });
      });

      return all_prods;

    },

    charts_collection() {
      return this.$store.state.manage.charts_collection;
    },

    productContent(product) {
      let str = "";

      if (product.enc)
        str += "ENCs, ";
      if (product.s102)
        str += "S102, ";
      if (product.poi)
        str += "POI, ";
      if (product.ntm)
        str += "NTM, ";
      if (product.ais)
        str += "AIS, ";
      if (product.weather)
        str += "Weather, ";
      if (product.navMsg)
        str += "Nav.Messages, ";
      if (product.noaaWeather)
        str += "NOAA Weather, ";
      if (product.pollution)
        str += "Pollution, ";

      str = utils.truncStrng(str, 20);

      return str;
    },

    isOutdated(date, type) {
      if (type.toLowerCase() === "free") {
        return "success";
      }
      let days = utils.daysOldWithSign(date);
      return days <= 0 ? "danger" : "success";
    },

    okHandler() {
      localStorage.setItem('disable-charts-hint', "true");
    }
  },

  mounted() {

    this.interval = setInterval(checkInternetLoop, 5000)

    if (!store.state.fickle.offline.userDataReady) {
      loadUserData().then(resp => {
        console.log("off, USER DONE")
      })
    }

    // alert(store.state.fickle.offline.ukhoEncDataReady)
    // debugger; // eslint-disable-line no-debugger
    if (!store.state.fickle.offline.ukhoEncDataReady) {
      // store.commit("addPrimarMapInfos", []);
      loadENCData("ukho").then(resp => {
        // alert("ukho")
        console.log("off, ENC DONE")
        this.init_map()
      })
    } else {
      this.init_map()
    }

    // setTimeout(() => {
    //   if (this.momentaryStore.route.chartsbyroute.length > 0) {
    //     this.momentaryStore.route.chartsbyroute.forEach((item) => {
    //       this.map.onCellClicked(item.trim());
    //     });
    //     this.map.dispatchSelectedCells();
    //     this.momentaryStore.route.clearChartsByRoute()
    //   }
    // }, 3000);

    onresize = (e) => {
      this.windowWidth = e.target.innerWidth;
    }

    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }


    if (this.$store.state.charts.s63.latest_order.status !== 'pre_order' && this.$store.state.proto.order.update) {
      this.updateWarning = true
    }


    eventBus.$on('agreement-pay', () => {
      this.show_spinner = true
    })

//    this.init_map()

    eventBus.$on('network-error', (value) => {
      this.network.isError = true;
      this.network.code = value;
    })

    eventBus.$on('cell-not-found', (id) => {
      // if (!this.notFoundCells.includes(id))
      if (!this.notFoundCells.find(item => item.id === id) && id !== "")
        this.notFoundCells.push({id: id})
    })

    eventBus.$on('search-maps', () => {
      this.$root.$emit('bv::toggle::collapse', 'search-maps')
      this.$root.$emit('bv::refresh::table', 'search-table')
    })

    eventBus.$on('charts-loaded', () => {
      this.show_table = true;
    })

    eventBus.$on('select-ship-from-ships', (payload) => {

      eventBus.$emit('set-new-ship-text-on-chart', this.$store.state.proto.order.ecdis_name)
      eventBus.$emit('ship-selected-from-chart')
      this.$store.dispatch('proto/updateOrder', {step: "creating"})

    })

  },

  beforeDestroy() {

    clearInterval(this.interval)

    this.map.clearCellslayer()
    clearInterval(this.orto);
    this.$store.commit("setAllSummaryClear");
  }
}
</script>

<style scoped>
</style>
