import {eventBus} from "@/main";

export function prepareError(errorCode, points) {
    let text = 'Error: (' + errorCode + ') '
    switch (errorCode) {
        case -100: text = 'There is no general route'
            break;
        case -5: text = 'Non-detailed cells were used'
            break;
        case -4: text = 'Compound'
            break;
        case -3: text = 'The second passage was not found'
            break;
        case -2: text = 'Not all cells are loaded'
            break;
        case -1: text = 'Data processing needs'
            break;
        case 1: text = 'Internal error'
            break;
        case 2: text = 'Memeory lack'
            break;
        case 3: text = 'Wrong starting point'
                break;
        case 4: text = 'Wrong finish point'
                break;
        case 5: text = 'Wrong depth'
                break;
        case 6: text = 'Too long route'
                break;
        case 7: text = 'The first passage was not found'
                break;
        case 8: text = 'The first passage was not found'
                break;
        case 9: text = 'Compound error'
            break;

        default: text = 'Can\'t compute the route'
    }
    text = 'Error: (' + errorCode + ') ' + text + "<br><div class='smt2s'>" + points + "</div>"

    eventBus.$emit('show-route-error', text)
}
