import { defineStore } from 'pinia'

export const useRoutesStore = defineStore({
    id: 'routes',
    state: () => ({
        route: [],
        routeInfo: [],
        routeDangerObjects: [],
        routeGeneral: [],
        routeLegs: [],
        routeSettings: {
            draught: 5,
            height: 20,
            radius: 120,
            xtel: 120,
            xter: 120,
            speed: 10,
            trackratio: 0.7,
            shallowratio: 1,
            avoidshallowstrength: 1,
            avoiddistance: 120,
            startdate: '',
            starttime: '',
            userecommendedtracks: true
        },
        nogoZones: []

    }),
    getters: {
        getRoute: (state) => state.route
    },
    actions: {
        addRoutePoints(val) {
            this.route = val
        },
        addRouteInfo(val) {
            this.routeInfo = val
        },
        addRouteDangerObjects(val) {
            this.routeDangerObjects = val
        },
        addRouteGeneral(val) {
            this.routeGeneral = val
        },
        addRouteLegs(val) {
            this.routeLegs = val
        },
        addRouteSettings(val) {
            this.routeSettings = val
        },
        addRouteDate(val) {
            this.routeSettings.startdate = val
        },
        addRouteTime(val) {
            this.routeSettings.starttime = val
        },
        addNoGoZone(val) {
            this.nogoZones.push(val)
        },
        deleteNoGoZone(val) {
            let index = this.nogoZones.findIndex(p => p.name == val);
            this.nogoZones.splice(index, 1);
        },
        reset() {
            this.route = []
            this.routeInfo = []
            this.routeDangerObjects = []
            this.routeGeneral = []
            this.routeLegs = []
        }
    },
    persist: true
})
