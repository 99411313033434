import {NavLeaf} from "../nav-leaf";
import "../extensions/leaflet-corridor"
import "../extensions/direct"
import './route-styles.css'
import "../extensions/leaflet-search/leaflet-search.min.js"
import "../extensions/leaflet-search/leaflet-search.min.css"
import '@fortawesome/fontawesome-free/js/fontawesome'
//import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
// import '@fortawesome/fontawesome-free/js/brands'
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure'
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.css'

import {
    createMakeRouteControl,
    createMakeNoGoControl,
    createEasyButtons,
    createWPControl,
    createMoveControl,
    createClearControl,
    createSafetyControl,
    createRecalculateControl,
    createRecalculateWPControl,
    createSafetyWPControl,
    createMeasureControl,
    createWeatherTextBox
} from './route-buttons';
import L from "leaflet";

import {useRoutesStore} from "@/stores/routes";

export class RouteBaseControlsLeaf extends NavLeaf {
    routesStore = null
    wpControl;
    moveControl;
    autorouteControl;
    nogoControl;
    drawControl;
    clearControl;
    recalculateControl;
    recalculateWPControl;
    safetyControl;
    safetyWPControl;
    measureControl;
    weatherText;

    constructor() {
        super();
    }

    async initbasecontrols(el) {
        super.init(el);

        this.routesStore = useRoutesStore()

        let button = createClearControl()
        this.map.addControl(this.clearControl = new button)

        button = createSafetyControl()
        this.map.addControl(this.safetyControl = new button)

        button = createSafetyWPControl()
        this.map.addControl(this.safetyWPControl = new button)

        button = createRecalculateControl()
        this.map.addControl(this.recalculateControl = new button)

        button = createRecalculateWPControl()
        this.map.addControl(this.recalculateWPControl = new button)

        button = createMakeRouteControl()
        this.map.addControl(this.autorouteControl = new button)

        button = createWPControl()
        this.map.addControl(this.wpControl = new button)

        button = createMoveControl()
        this.moveControl = new button

        button = createMakeNoGoControl()
        this.map.addControl(this.nogoControl = new button)

        createEasyButtons(this.map)

        this.measureControl = createMeasureControl().addTo(this.map)
        this.measureControl._clearMeasureControl.hidden = true
        this.measureControl._unitControl.hidden = true

        this.weatherText = createWeatherTextBox().addTo(this.map)
    }

    changeWeatherText = (date) => {
        if (date)
            this.weatherText._container.innerHTML = "<h6>" + 'Weather data: ' + date + "</h6>"
        else
            this.weatherText._container.innerHTML = ""
    }
}
